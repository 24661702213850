import { defaultTheme } from 'src/styles/theme'
import styled from 'styled-components/macro'

const { breakpoints, nav, walletFooter } = defaultTheme

export const Main = styled.main`
  height: 100%;
  min-height: calc(100vh - ${nav.height} - ${walletFooter.height});

  @media screen and (min-width: ${breakpoints.medium}) {
    min-height: calc(100vh - ${nav.height});
  }

  background: radial-gradient(
      ellipse at top,
      rgba(12, 65, 115, 1),
      rgba(12, 53, 115, 0) 45%
    ),
    radial-gradient(
      ellipse at bottom,
      rgba(69, 25, 124, 1),
      rgba(69, 25, 124, 0) 45%
    );

  @media screen and (max-width: ${breakpoints.large}) {
    background: radial-gradient(
        circle at top,
        rgba(12, 65, 115, 1),
        rgba(12, 53, 115, 0) 40%
      ),
      radial-gradient(
        circle at bottom,
        rgba(69, 25, 124, 1),
        rgba(69, 25, 124, 0) 40%
      );
  }
`
