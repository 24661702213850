import { BigNumber } from 'ethers'
import { parseEther } from 'ethers/lib/utils'

import wethBalances from './weth-balances.json'

const wethBalancesParsed = JSON.parse(JSON.stringify(wethBalances))
const formattedWETHArray = Object.keys(wethBalancesParsed).map(
  (key: string) => ({
    account: key,
    balance: BigNumber.from(parseEther(wethBalancesParsed[key])),
  })
)

export const treeData = [...formattedWETHArray]
