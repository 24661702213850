import { getTxExplorerUrl } from 'src/utils/helpers'

import { Container, Wrapper } from './styles'

interface IWalletNotification {
  title: string
  message?: string
  txHash?: string
}

export const WalletNotification = ({
  title,
  message,
  txHash,
}: IWalletNotification): JSX.Element => {
  return (
    <Wrapper>
      <Container>
        <div>
          <p>{title}</p>

          {message && <p>{message}</p>}

          <a
            className="notification-link"
            href={getTxExplorerUrl(txHash)}
            target="_blank"
            rel="noreferrer"
          >
            View on explorer
          </a>
        </div>
      </Container>
    </Wrapper>
  )
}
