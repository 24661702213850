import { BigNumber, utils } from 'ethers'

import MerkleTree from './merkle-tree'

export default class WETHBalanceTree {
  private readonly tree: MerkleTree
  constructor(
    balances: {
      account: string
      balance: BigNumber
    }[]
  ) {
    this.tree = new MerkleTree(
      balances.map(({ account, balance }, index) => {
        return WETHBalanceTree.toNode(index, account, balance)
      })
    )
  }

  public static verifyProof(
    index: number | BigNumber,
    account: string,
    balance: BigNumber,
    proof: Buffer[],
    root: Buffer
  ): boolean {
    let pair = WETHBalanceTree.toNode(index, account, balance)
    for (const item of proof) {
      pair = MerkleTree.combinedHash(pair, item)
    }

    return pair.equals(root)
  }

  // keccak256(abi.encode(index, account, balance))
  public static toNode(
    index: number | BigNumber,
    account: string,
    balance: BigNumber
  ): Buffer {
    return Buffer.from(
      utils
        .solidityKeccak256(
          ['uint256', 'address', 'uint256'],
          [index, account, balance]
        )
        .substr(2),
      'hex'
    )
  }

  public getHexRoot(): string {
    return this.tree.getHexRoot()
  }

  // returns the hex bytes32 balances of the proof
  public getProof(
    index: number | BigNumber,
    account: string,
    balance: BigNumber
  ): string[] {
    return this.tree.getHexProof(
      WETHBalanceTree.toNode(index, account, balance)
    )
  }
}
