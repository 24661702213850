import {
  Box,
  buildResponsiveStyleString,
  groupBreakpoints,
  StyledValue,
} from '@illuvium/illuvium-design'
import { withoutDomProps } from 'src/utils/helpers'
import styled from 'styled-components'

export interface IStyledText {
  isHeader?: boolean
  lineHeight?: StyledValue<number | string>
}

export const StyledText = styled(Box).withConfig(
  withoutDomProps('isHeader', 'lineHeight')
)<IStyledText>((props) => {
  const breakpoints = groupBreakpoints<any>(props)

  return buildResponsiveStyleString(breakpoints, (breakpoint) => {
    const { isHeader, lineHeight } = breakpoint

    let styleStr = ''

    if (isHeader === true) {
      styleStr += `text-shadow: 0 1px 0 #7d9be8, 0px -4px 16px rgba(171, 84, 244, 0.72);`
    }

    if (lineHeight !== undefined) {
      styleStr += `line-height: ${lineHeight};`
    }

    return styleStr
  })
})
