import { Box, BoxProps } from '@illuvium/illuvium-design'
import React from 'react'

import { StyledButton } from './styles'

export type ExtendsButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>

export type ButtonProps = {
  label?: string
  isHolo?: boolean
  isActive?: boolean
  disabled?: boolean
} & ExtendsButtonProps &
  BoxProps

export const Button: React.FC<ButtonProps> = (props) => {
  const { children, label, ...rest } = props

  return (
    <StyledButton
      px={{ base: 2, lg: 3 }}
      py={3}
      children={<Box mt={-0.5}>{label ?? children}</Box>}
      {...rest}
    />
  )
}
