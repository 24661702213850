import React from 'react'
import { CSSTransition, SwitchTransition } from 'react-transition-group'

export interface SwitchValueProps {
  value: string | number
}

export interface TransitionSwitchEffectProps {
  value: string | number
  timeout?: number
  classNames?: string
}

export const SwitchFragment: React.FC<SwitchValueProps> = (props) => {
  const { children } = props
  return <React.Fragment>{children}</React.Fragment>
}

export const SwitchTransitionEffect: React.FC<TransitionSwitchEffectProps> = (
  props
) => {
  const { children, value, timeout = 400, classNames = 'fade' } = props

  return (
    <SwitchTransition mode={'out-in'}>
      <CSSTransition
        key={value}
        classNames={classNames}
        timeout={timeout}
        unmountOnExit={true}
        appear={true}
      >
        <React.Fragment>
          {React.Children.map(children, (el) => {
            if (!React.isValidElement(el)) return undefined
            return el.props.value === value && el
          })}
        </React.Fragment>
      </CSSTransition>
    </SwitchTransition>
  )
}
