import { useAwait } from '@dev-plus-plus/react-await'
import React from 'react'
import * as Local from 'src/components/setup-provider'
import { useSetupBlockchain } from 'src/hooks/useSetupBlockchain'
import { useSetupGasTracker } from 'src/hooks/useSetupGasTracker'
import { useSetupRecentTransactions } from 'src/hooks/useSetupRecentTransactions'
import { useSetupScrollTop } from 'src/hooks/useSetupScrollTop'
import { useSetupWallet } from 'src/hooks/useSetupWallet'

const REFRESH_TIMER = 60 * 1000

export const SetupProvider: React.FC = (props) => {
  const { children } = props

  useSetupScrollTop() // scrolls to top when changes the route

  useSetupWallet() // caches wallet connector data from LocalStorage
  useSetupRecentTransactions() // caches recent transactions from LocalStorage

  const setupBlockchain = useSetupBlockchain(REFRESH_TIMER) // caches and syncs block height
  const setupGasTracker = useSetupGasTracker(REFRESH_TIMER) // caches and syncs Gas fees from Etherscan

  const { isFirstBlockchain, handlerBlockchain, refreshBlockchain } =
    setupBlockchain
  const { isFirstGasTracker, handlerGasTracker, refreshGasTracker } =
    setupGasTracker

  const handlerSetup = useAwait('populate@Setup')

  const deps = [isFirstGasTracker, isFirstBlockchain]

  React.useEffect(() => {
    // starts setup loader when every deps hasn't loaded for the first time
    if (deps.every((it) => !it)) {
      handlerSetup.controller.init()
    }

    // ends setup loader when every deps has loaded for the first time
    if (deps.every((it) => it)) {
      handlerSetup.controller.done()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps])

  const providerValue: Local.ContextProps = {
    handlerSetup,

    handlerBlockchain,
    refreshBlockchain,

    handlerGasTracker,
    refreshGasTracker,
  }

  return <Local.Context.Provider value={providerValue} children={children} />
}
