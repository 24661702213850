import 'normalize.css'

import { defaultTheme } from 'src/styles/theme'
import { createGlobalStyle } from 'styled-components'

const { palette, fonts, spacing, nav, notifications, breakpoints } =
  defaultTheme

export const GlobalStyles = createGlobalStyle`
  html, body {
    max-width: 100vw;
  }

  * {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  @font-face {
    font-family:"sofia-pro";
    src:url("https://use.typekit.net/af/09d302/00000000000000007735a14e/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/09d302/00000000000000007735a14e/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/09d302/00000000000000007735a14e/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:700;
  }

  @font-face {
    font-family:"sofia-pro";
    src:url("https://use.typekit.net/af/e30e66/00000000000000007735a151/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff2"),url("https://use.typekit.net/af/e30e66/00000000000000007735a151/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff"),url("https://use.typekit.net/af/e30e66/00000000000000007735a151/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("opentype");
    font-display:auto;font-style:italic;font-weight:700;
  }

  @font-face {
    font-family:"sofia-pro";
    src:url("https://use.typekit.net/af/5e6988/00000000000000007735a163/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/5e6988/00000000000000007735a163/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/5e6988/00000000000000007735a163/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:400;
  }

  @font-face {
    font-family:"sofia-pro";
    src:url("https://use.typekit.net/af/37de2f/00000000000000007735a165/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"),url("https://use.typekit.net/af/37de2f/00000000000000007735a165/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"),url("https://use.typekit.net/af/37de2f/00000000000000007735a165/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
    font-display:auto;font-style:italic;font-weight:400;
  }

  html {
    font-family: sofia-pro, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 16px;
  }

  body {
    margin: 0;
    background-color: ${palette.inputBg.main};
    color: ${palette.inputBg.contrastText}
  }

  a {
    text-decoration: none;
  }

  p {
    line-height: ${fonts.lineHeight};
  }

  #notifications {
    max-width: 100%;
    left: ${spacing.base * 1.5}px;

    z-index: ${notifications.zIndex};
    position: fixed;

    top: ${nav.height};

    right: ${spacing.base * 3}px;
    margin-top: ${spacing.base * 3}px;

    .alert-container {
      & > div {
        margin-bottom: ${spacing.base * 3}px;
      } 
    }

    @media screen and (min-width:${breakpoints.medium}) {
      left: unset;
    }
  }

  #nprogress .bar {
    background-color: ${palette.primary.main};
    height: 4px;
  }
`
