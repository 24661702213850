import { Box, toSpace } from '@illuvium/illuvium-design'
import { defaultTheme } from 'src/styles/theme'
import { withoutDomProps } from 'src/utils/helpers'
import { css } from 'styled-components'
import styled from 'styled-components/macro'

const { spacing, palette, fonts, button, border, breakpoints } = defaultTheme
const { gradients } = palette

interface IDropdownGasPrice {
  isFooter?: boolean
}

export const GasIconContainer = styled.div`
  display: flex;
  position: relative;
  align-self: center;
  align-items: center;
  justify-content: center;

  height: 46px;
  width: 65px;

  cursor: pointer;

  & > span {
    margin-left: ${spacing.base}px;
  }

  text-transform: uppercase;
  letter-spacing: 0.05rem;

  padding: ${spacing.base * 2}px ${spacing.base * 2}px;
  border-radius: ${button.radius};
  border: ${border.size} solid ${palette.common.black};
  font-weight: ${fonts.weight.bold};

  background: ${gradients.secondary.main};
  color: ${gradients.secondary.contrastText};

  &:hover {
    background: ${gradients.secondary.hover};
  }

  @media screen and (min-width: ${breakpoints.medium}) {
    margin-left: ${spacing.base * 3}px;
  }
`

export const IconText = styled.span`
  display: flex;

  & > span {
    padding-bottom: 3px;
    font-size: 13px;
    letter-spacing: 0.14em;
  }
`

export const DropdownGasPrice = styled.div.withConfig(
  withoutDomProps('isFooter')
)<IDropdownGasPrice>`
  user-select: none;
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 280px;

  position: absolute;

  ${({ isFooter }) => {
    if (isFooter) {
      return css`
        bottom: ${toSpace(16)};
        left: 0;

        &:before {
          content: ' ';
          position: absolute;
          bottom: -7px;
          left: 2rem;
          height: 0.75rem;
          width: 0.75rem;
          transform: rotate(45deg);
          background: inherit;
          border-bottom: inherit;
          border-right: inherit;
        }
      `
    } else {
      return css`
        top: ${toSpace(18)};
        right: 0;

        &:before {
          content: ' ';
          position: absolute;
          top: -7px;
          right: 2rem;
          height: 0.75rem;
          width: 0.75rem;
          transform: rotate(225deg);
          background: inherit;
          border-bottom: inherit;
          border-right: inherit;
        }
      `
    }
  }}

  z-index: 1;
  border: 1px solid ${palette.common.black};
  background-color: ${palette.inputBg.main};
  border-radius: ${border.radius};
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.24);

  padding: ${spacing.base * 4}px ${spacing.base * 5}px ${spacing.base * 5}px
    ${spacing.base * 5}px;

  & > h4 {
    margin: 0 ${spacing.base}px ${spacing.base * 4}px 0;
  }
  & > p {
    margin: ${spacing.base}px;
    font-size: 14px;
    line-height: 16px;
    color: ${palette.lightBlue.main};
  }
  & > hr {
    padding: 0;
    margin: 0 0 ${spacing.base * 2}px 0;
    width: 100%;
  }
`

export const DropdownBackdrop = styled(Box)`
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1;
`

export const LoadingBody = styled.div`
  width: 24px;
  height: 24px;
  padding: 0;
  margin: 0 0 ${spacing.base * 4}px ${spacing.base * 8}px;
`
