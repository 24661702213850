import { ethers } from 'ethers'
import { Blockchain, blockchainAtom } from 'src/recoil/blockchain'
import { NETWORK_NAME } from 'src/utils/constants'

import { useSyncAtom } from './useSyncAtom'

export function useSetupBlockchain(refreshTimer?: number) {
  const populate = async () => {
    const provider = ethers.providers.getDefaultProvider(NETWORK_NAME, {
      alchemy:
        NETWORK_NAME === 'rinkeby'
          ? process.env.REACT_APP_ALCHEMY_KEY_RINKEBY
          : process.env.REACT_APP_ALCHEMY_KEY_PROD,
      etherscan: process.env.REACT_APP_ETHERSCAN_KEY,
      infura: process.env.REACT_APP_INFURA_KEY,
    })
    const blockNumber = await provider.getBlockNumber()

    return { blockNumber } as Blockchain
  }

  const { isFirstLoad, handler, refresh } = useSyncAtom(
    blockchainAtom,
    populate,
    refreshTimer
  )

  return {
    isFirstBlockchain: isFirstLoad,
    handlerBlockchain: handler,
    refreshBlockchain: refresh,
  }
}
