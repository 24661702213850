import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { defaultTheme } from 'src/styles/theme'

const { mediaQueries } = defaultTheme

export type BreakpointType = 'sm' | 'md' | 'lg' | 'xl' | 'base'

export const useResponsive = () => {
  const isSmall = useMediaQuery({
    query: mediaQueries.small,
  })

  const isMedium = useMediaQuery({
    query: mediaQueries.medium,
  })

  const isLarge = useMediaQuery({
    query: mediaQueries.large,
  })

  const isExtraLarge = useMediaQuery({
    query: mediaQueries.extraLarge,
  })

  const breakpoint = React.useMemo<BreakpointType>(() => {
    if (isExtraLarge) return 'xl'
    if (isLarge) return 'lg'
    if (isMedium) return 'md'
    if (isSmall) return 'sm'

    return 'base'
  }, [isExtraLarge, isLarge, isMedium, isSmall])

  const isMediumDesktop = breakpoint === 'xl'
  const isSmallDesktop = breakpoint === 'lg'
  const isTablet = breakpoint === 'md'
  const isMobile = breakpoint === 'sm'

  const isDesktop = isSmallDesktop || isMediumDesktop
  const isTabletOrDesktop = isTablet || isDesktop
  const isTabletOrMobile = isTablet || isMobile

  return {
    breakpoint,
    isDesktop,
    isMediumDesktop,
    isSmallDesktop,
    isTablet,
    isTabletOrDesktop,
    isTabletOrMobile,
    isMobile,
  }
}
