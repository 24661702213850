import { Box } from '@illuvium/illuvium-design'
import React from 'react'
import { Button, ButtonProps } from 'src/components/button'

export const ButtonAddress: React.FC<ButtonProps> = (props) => {
  const { label, ...rest } = props

  return (
    <Button px={0} py={'1px'} isHolo={true} {...rest}>
      <Box px={4} mt={'1px'} py={2.5} bg={'inputBg.main'} rounded={'5px'}>
        {label}
      </Box>
    </Button>
  )
}
