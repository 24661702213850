import { Box, HStack, VStack } from '@illuvium/illuvium-design'
import { capitalize } from 'lodash'
import React from 'react'
import { Modal, ModalProps } from 'src/components/modal'

export interface ModalWrongNetworkProps extends ModalProps {
  network?: string
}

export const ModalWrongNetwork: React.FC<ModalWrongNetworkProps> = (props) => {
  const { network = 'MAINNET', ...rest } = props

  return (
    <Modal p={{ base: 4, md: 10 }} maxW={100} {...rest}>
      <VStack spacing={8} mt={-3}>
        <HStack>
          <Box
            as={'img'}
            h={6}
            src={'./images/icons/error.png'}
            alt={'error'}
            mb={-1}
          />

          <Box fontSize={5} fontWeight={'bold'}>
            Wrong Network
          </Box>
        </HStack>

        <Box textAlign={'center'} fontSize={3.5}>
          Please switch your wallet network to {capitalize(network)} to use the
          app
        </Box>
      </VStack>
    </Modal>
  )
}
