import type { UseAwaitReturn } from '@dev-plus-plus/react-await'
import React from 'react'

export interface ContextProps {
  handlerSetup: UseAwaitReturn

  handlerBlockchain: UseAwaitReturn
  refreshBlockchain: () => Promise<void>

  handlerGasTracker: UseAwaitReturn
  refreshGasTracker: () => Promise<void>
}

export const Context = React.createContext<ContextProps>(undefined as any)
