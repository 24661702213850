import React from 'react'

export function useInterval(callback: () => void, delay?: number | null) {
  const savedCallback = React.useRef(callback)

  // Remember the latest callback.
  React.useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  React.useEffect(() => {
    const tick = () => savedCallback.current()

    if (delay !== null) {
      const id = setInterval(tick, Math.max(delay ?? 0, 100))
      return () => clearInterval(id)
    }
  }, [delay])
}
