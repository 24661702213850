import { AwaitConsumer } from '@dev-plus-plus/react-await'
import { Box, BoxProps, SimpleGrid, VStack } from '@illuvium/illuvium-design'
import React from 'react'
import { Button } from 'src/components/button'
import { SmallSpinner } from 'src/components/small-spinner'
import { ConnectorNames } from 'src/constants/wallet'
import { useWalletConnector } from 'src/hooks/useWalletConnector'

import { WalletBadge } from './wallet-badge'

export interface WalletCategoryWebProps extends BoxProps {
  onConnect?: (connectorName: ConnectorNames) => void
  onError?: (e: Error) => void
}

export const WalletCategoryWeb: React.FC<WalletCategoryWebProps> = (props) => {
  const { onConnect, onError, ...rest } = props

  const { connect, connectorHandler } = useWalletConnector()

  const connectWallet = async (connectorName: ConnectorNames) => {
    try {
      await connect(connectorName)
      onConnect?.(connectorName)
    } catch (e: any) {
      onError?.(e)
      console.error(e)
    }
  }

  return (
    <VStack spacing={6} {...rest}>
      <Box textAlign={'center'} fontSize={3.5} color={'lightBlue.main'}>
        Requires log in details, or a phone to scan QR code
      </Box>

      <AwaitConsumer
        loader={connectorHandler.loader}
        loadingView={<SmallSpinner />}
      >
        <SimpleGrid flex={1} columns={3} spacing={4}>
          <Button onClick={() => connectWallet(ConnectorNames.Fortmatic)}>
            <WalletBadge icon={'fortmatic.png'} label={'Fortmatic'} />
          </Button>

          <Button onClick={() => connectWallet(ConnectorNames.Portis)}>
            <WalletBadge icon={'portis.png'} label={'Portis'} />
          </Button>

          <Button onClick={() => connectWallet(ConnectorNames.Torus)}>
            <WalletBadge icon={'torus.png'} label={'Torus'} />
          </Button>

          <Button onClick={() => connectWallet(ConnectorNames.WalletLink)}>
            <WalletBadge icon={'coinbase.svg'} label={'Coinbase'} />
          </Button>

          <Button onClick={() => connectWallet(ConnectorNames.WalletConnect)}>
            <WalletBadge icon={'wallet-connect.svg'} label={'Wallet Con.'} />
          </Button>
        </SimpleGrid>
      </AwaitConsumer>
    </VStack>
  )
}
