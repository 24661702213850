import { Box, HStack } from '@illuvium/illuvium-design'
import React from 'react'
import { Button, ButtonProps } from 'src/components/button'
import { PlusIcon } from 'src/components/icons'

export const ButtonConnect: React.FC<ButtonProps> = (props) => {
  const { label, ...rest } = props

  return (
    <Button isHolo={true} {...rest}>
      <HStack>
        <Box mb={-1}>
          <PlusIcon />
        </Box>

        <Box as={'span'}>{label}</Box>
      </HStack>
    </Button>
  )
}
