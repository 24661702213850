import { AwaitConsumer } from '@dev-plus-plus/react-await'
import { Box, HStack } from '@illuvium/illuvium-design'
import React from 'react'
import { ButtonProps } from 'src/components/button'
import * as Local from 'src/components/button-wallet'
import { ModalWalletConnector } from 'src/components/modal-wallet-connect'
import { SmallSpinner } from 'src/components/small-spinner'
import { TransitionEffect } from 'src/components/transition-effect'
import { useDisclosure } from 'src/hooks/useDisclosure'
import { useWalletConnector } from 'src/hooks/useWalletConnector'

export interface ButtonWalletProps extends ButtonProps {
  isFooter?: boolean
  useSoftLoader?: boolean
}

export const ButtonWallet: React.FC<ButtonWalletProps> = (props) => {
  const { isFooter, useSoftLoader, ...rest } = props

  const { connectorHandler } = useWalletConnector()

  const modalWalletConnector = useDisclosure()
  const dropdownAccount = useDisclosure()

  const providerValue: Local.ContextProps = {
    modalWalletConnector,
    dropdownAccount,
  }

  return (
    <Local.Context.Provider value={providerValue}>
      <Box position={'relative'}>
        <HStack>
          <TransitionEffect
            isActive={useSoftLoader}
            activeView={
              <>
                <Box w={8}>
                  <AwaitConsumer
                    loader={connectorHandler.loader}
                    loadingView={<SmallSpinner />}
                  />
                </Box>

                <Local.SlotButtonContainer {...rest} />
              </>
            }
            inactiveView={
              <AwaitConsumer
                loader={connectorHandler.loader}
                loadingView={<SmallSpinner />}
              >
                <Local.SlotButtonContainer {...rest} />
              </AwaitConsumer>
            }
          />
        </HStack>

        <ModalWalletConnector
          isOpen={modalWalletConnector.isOpen}
          onClose={modalWalletConnector.onClose}
        />

        <Local.DropdownAccount
          isOpen={dropdownAccount.isOpen}
          onClose={dropdownAccount.onClose}
          isFooter={isFooter}
        />
      </Box>
    </Local.Context.Provider>
  )
}
