import { BoxProps } from '@illuvium/illuvium-design'
import React from 'react'

import {
  BasicDividerBox,
  DividerBox,
  HoloDividerBox,
  VerticalDividerBox,
} from './styles'

interface DividerProps extends BoxProps {
  variant?: 'basic' | 'holo' | 'vertical'
}

export const Divider: React.FC<DividerProps> = (props) => {
  const { variant, ...rest } = props
  switch (variant) {
    case 'basic':
      return <BasicDividerBox {...rest} />
    case 'holo':
      return <HoloDividerBox {...rest} />
    case 'vertical':
      return <VerticalDividerBox {...rest} />
    default:
      return <DividerBox {...rest} />
  }
}
