import { Box, StackProps, VStack } from '@illuvium/illuvium-design'
import React from 'react'

export interface WalletBadgeProps extends StackProps {
  icon?: string
  label?: string
}

export const WalletBadge: React.FC<WalletBadgeProps> = (props) => {
  const { icon, label, ...rest } = props

  return (
    <VStack {...rest}>
      <Box as={'img'} h={6} src={`./images/wallets/${icon}`} alt={'icon'} />

      <Box fontSize={[2.5, 3, 3.5]} fontWeight={'bold'} textAlign={'center'}>
        {label}
      </Box>
    </VStack>
  )
}
