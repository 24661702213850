export const APP_NAME = 'Illuvium | Mint WETH'

export const INFURA_KEY = process.env.REACT_APP_INFURA_KEY as string
export const NETWORK_NAME = process.env.REACT_APP_NETWORK as string
export const NETWORK_ID = process.env.REACT_APP_NETWORK_ID as string
export const COINGECKO_KEY = process.env.REACT_APP_COINGECKO_KEY as string

export const WETH_MINT_ID = process.env
  .REACT_APP_WETH_MINT_CONTRACT_ID as string

export const WETH_ID = process.env.REACT_APP_WETH as string

export const MAINNET_ILV_TOKEN = '0x767fe9edc9e0df98e07454847909b5e959d7ca0e'

/* localStorage */
export const WALLET_CONNECTION = 'walletconnection'
export const RECENT_TX_KEY = 'recent-transactions'
export const BROWSER_WALLET_LABEL = 'Browser Wallet'

/*  */
export const AMOUNT_TO_WITHDRAW = 'amountToWithdraw'

/* Etherscan*/
export const ETHERSCAN_API_URL = process.env
  .REACT_APP_ETHERSCAN_API_URL as string
export const ETHERSCAN_KEY = process.env.REACT_APP_ETHERSCAN_KEY as string
