import { AwaitConsumer } from '@dev-plus-plus/react-await'
import { Box, BoxProps, SimpleGrid, VStack } from '@illuvium/illuvium-design'
import React from 'react'
import { Button } from 'src/components/button'
import { SmallSpinner } from 'src/components/small-spinner'
import { ConnectorNames } from 'src/constants/wallet'
import { useWalletConnector } from 'src/hooks/useWalletConnector'

import { WalletBadge } from './wallet-badge'

export interface WalletCategoryBrowserProps extends BoxProps {
  onConnect?: (connectorName: ConnectorNames) => void
  onError?: (e: Error) => void
}

export const WalletCategoryBrowser: React.FC<WalletCategoryBrowserProps> = (
  props
) => {
  const { onConnect, onError, ...rest } = props

  const { connect, connectorHandler } = useWalletConnector()

  const connectWallet = async (connectorName: ConnectorNames) => {
    try {
      await connect(connectorName)
      onConnect?.(connectorName)
    } catch (e: any) {
      onError?.(e)
      console.error(e)
    }
  }

  return (
    <VStack spacing={6} {...rest}>
      <Box textAlign={'center'} fontSize={3.5} color={'lightBlue.main'}>
        Automatically detects your default browser wallet
      </Box>

      <SimpleGrid flex={1} columns={3} spacing={[2, 3, 4]} alignSelf={'center'}>
        <WalletBadge icon={'metamask.png'} label={'Metamask'} />

        <WalletBadge icon={'trust-wallet.png'} label={'Trust Wallet'} />

        <WalletBadge icon={'other.png'} label={'Other'} />
      </SimpleGrid>

      <AwaitConsumer
        loader={connectorHandler.loader}
        loadingView={<SmallSpinner />}
      >
        <Button
          label={'Connect Wallet'}
          onClick={() => connectWallet(ConnectorNames.Browser)}
          isHolo={true}
        />
      </AwaitConsumer>
    </VStack>
  )
}
