import { truncateAddress } from '@illuvium/illuvium-design'
import React from 'react'
import { ButtonProps } from 'src/components/button'
import * as Local from 'src/components/button-wallet'
import { TransitionEffect } from 'src/components/transition-effect'
import { useWalletConnector } from 'src/hooks/useWalletConnector'

export const SlotButtonContainer: React.FC<ButtonProps> = (props) => {
  const { ...rest } = props

  const { isConnected, wallet } = useWalletConnector()

  const { dropdownAccount, modalWalletConnector } = React.useContext(
    Local.Context
  )

  const labelButton = React.useMemo(
    () =>
      isConnected && wallet
        ? truncateAddress(wallet.address)
        : 'Connect Wallet',
    [isConnected, wallet]
  )

  const clickEvent = () => {
    if (isConnected) {
      dropdownAccount.onToggle()
    } else {
      modalWalletConnector.onToggle()
    }
  }

  return (
    <TransitionEffect
      isActive={isConnected}
      activeView={
        <Local.ButtonAddress
          label={labelButton}
          onClick={clickEvent}
          {...rest}
        />
      }
      inactiveView={
        <Local.ButtonConnect
          label={labelButton}
          onClick={clickEvent}
          {...rest}
        />
      }
    />
  )
}
