import React from 'react'
import { useRecoilState } from 'recoil'
import { ConnectorNames, SUPPORTED_WALLETS } from 'src/constants/wallet'
import { walletAtom } from 'src/recoil/wallet'

import { useIsMounted } from './useIsMounted'
import { useWalletConnector } from './useWalletConnector'

export function useSetupWallet() {
  const {
    connect,
    clearCache,
    fixLegacyConnectorStorage,
    connectorName,
    connectorStorage,
    web3Context,
  } = useWalletConnector()

  const { account } = web3Context

  const [, setWallet] = useRecoilState(walletAtom)

  React.useEffect(() => {
    function loadConnector() {
      try {
        fixLegacyConnectorStorage() // keep users from v1 connected
        const connectorName = connectorStorage.load()
        connectorName && connect(connectorName).then()
      } catch (e) {
        console.error(e)
      }
    }

    function cacheWallet(address: string, connectorName: ConnectorNames) {
      const { iconName } = SUPPORTED_WALLETS[connectorName]

      setWallet({
        connectorName,
        iconName,
        address,
      })
    }

    if (!isMounted) {
      // Trigger before mounts
      loadConnector()
    } else if (account && connectorName) {
      // Trigger when user connects
      cacheWallet(account, connectorName)
    } else {
      // Otherwise clean cache
      clearCache()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, connectorName])

  const { current: isMounted } = useIsMounted()
}
