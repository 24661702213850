import { AwaitConsumer, useAwait } from '@dev-plus-plus/react-await'
import { Box, HStack, Stack, VStack } from '@illuvium/illuvium-design'
import { ethers } from 'ethers'
import React from 'react'
import { useRecoilValue } from 'recoil'
import { Button } from 'src/components/button'
import { Card } from 'src/components/card'
import { SmallSpinner } from 'src/components/small-spinner'
import { Text } from 'src/components/text'
import { useClaimWETH } from 'src/hooks/useClaimWeth'
import { userDataAtom } from 'src/recoil/user-data'
import { WETH_ID } from 'src/utils/constants'
import { formatAmount } from 'src/utils/helpers'

declare global {
  interface Window {
    ethereum: any
  }
}

const { formatEther } = ethers.utils

export const HomeContext: React.FC = () => {
  const userData = useRecoilValue(userDataAtom)

  const [hasClaimed, setHasClaimed] = React.useState(false)
  const [hasAddedToken, setHasAddedToken] = React.useState(false)

  const { controller, loader } = useAwait('claim@HomeContext')
  const { claimWETH } = useClaimWETH()

  const claim = async () => {
    try {
      await claimWETH(userData)
      setHasClaimed(true)
    } catch (error) {
      console.log(error)
    }
  }

  const addToken = async () => {
    try {
      const wasTokenAdded = await window.ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: {
            address: WETH_ID,
            symbol: 'WETH',
            decimals: 18,
            image:
              'https://openseauserdata.com/files/accae6b6fb3888cbff27a013729c22dc.svg',
          },
        },
      })
      if (wasTokenAdded) {
        setHasAddedToken(true)
      }
    } catch (error) {
      console.log(error)
      setHasAddedToken(false)
    }
  }

  if (!userData) {
    return <></>
  }

  return (
    <VStack>
      <Card w={'full'} maxW={90}>
        <Stack spacing={6}>
          <HStack>
            <Box
              as={'img'}
              src={'./images/icons/weth.svg'}
              alt={'weth'}
              w={4}
            />

            <Text fontWeight={'bold'}>WETH</Text>
          </HStack>

          <Stack spacing={0} flex={1} minH={20}>
            {!hasClaimed && (
              <>
                <Text
                  lineHeight={'10px'}
                  fontSize={3.5}
                  color={'lightBlue.main'}
                >
                  Available to claim:
                </Text>

                <Box fontSize={6} mt={2} fontWeight={'bold'}>
                  {formatAmount(Number(formatEther(userData.balance)))} WETH
                </Box>
              </>
            )}

            {hasClaimed && (
              <>
                <Text fontSize={4}>
                  Your WETH tokens have been successfully claimed and will now
                  appear in your wallet.
                </Text>
              </>
            )}
          </Stack>

          {!hasClaimed && (
            <AwaitConsumer loader={loader} loadingView={<SmallSpinner />}>
              <Button
                w={'full'}
                onClick={() => controller.run(claim)}
                label={'Claim'}
                isHolo={true}
                disabled={hasClaimed}
              />
            </AwaitConsumer>
          )}

          {hasClaimed && (
            <AwaitConsumer loader={loader} loadingView={<SmallSpinner />}>
              <Button
                w={'full'}
                onClick={() => controller.run(addToken)}
                label={'Add Token to Wallet'}
                isHolo={true}
                disabled={hasAddedToken}
              />
            </AwaitConsumer>
          )}
        </Stack>
      </Card>
    </VStack>
  )
}
