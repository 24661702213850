import { Stack } from '@illuvium/illuvium-design'
import { UnsupportedChainIdError } from '@web3-react/core'
import React from 'react'
import { Divider } from 'src/components/divider'
import { Modal, ModalProps } from 'src/components/modal'
import { ModalWrongNetwork } from 'src/components/modal-wrong-network'
import { SelectNavbar, SelectOption } from 'src/components/select-navbar'
import { useDisclosure } from 'src/hooks/useDisclosure'
import { useWalletConnector } from 'src/hooks/useWalletConnector'
import { NETWORK_ID, NETWORK_NAME } from 'src/utils/constants'

import {
  SwitchFragment,
  SwitchTransitionEffect,
} from '../switch-transition-effect'
import { WalletCategoryBrowser } from './wallet-category-browser'
import { WalletCategoryHardware } from './wallet-category-hardware'
import { WalletCategoryWeb } from './wallet-category-web'

const walletOptions: SelectOption[] = [
  {
    id: 'browser',
    label: 'Browser',
  },
  {
    id: 'web',
    label: 'Web',
  },
  {
    id: 'hardware',
    label: 'Hardware',
  },
]

export interface ModalWalletConnectorProps extends ModalProps {}

export const ModalWalletConnector: React.FC<ModalWalletConnectorProps> = (
  props
) => {
  const { ...rest } = props
  const { isOpen, onClose } = props

  const { web3Context } = useWalletConnector()
  const { error, account, chainId } = web3Context

  const [connector, setConnector] = React.useState<SelectOption>(
    walletOptions[0]
  )

  const modalWrongNetwork = useDisclosure()

  React.useEffect(() => {
    const isUnsupportedChainIdError = error instanceof UnsupportedChainIdError
    const hasAccountAndChainId = account && chainId !== parseInt(NETWORK_ID)

    if (isUnsupportedChainIdError || hasAccountAndChainId) {
      modalWrongNetwork.onOpen()
    }
  }, [account, chainId, error, modalWrongNetwork])

  React.useEffect(() => {
    if (account) closeEvent()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, account])

  const closeEvent = () => {
    onClose?.()
  }

  return (
    <>
      <Modal
        title={'Connect Wallet'}
        spacing={5}
        maxW={100}
        minH={95}
        {...rest}
      >
        <Stack spacing={6} h={'full'}>
          <Divider variant={'holo'} />

          <SelectNavbar
            value={connector}
            onSelect={setConnector}
            options={walletOptions}
          />

          <SwitchTransitionEffect value={connector.id}>
            <SwitchFragment value={'browser'}>
              <WalletCategoryBrowser flex={1} onError={closeEvent} />
            </SwitchFragment>

            <SwitchFragment value={'web'}>
              <WalletCategoryWeb flex={1} onError={closeEvent} />
            </SwitchFragment>

            <SwitchFragment value={'hardware'}>
              <WalletCategoryHardware flex={1} onError={closeEvent} />
            </SwitchFragment>
          </SwitchTransitionEffect>
        </Stack>
      </Modal>

      <ModalWrongNetwork
        isOpen={modalWrongNetwork.isOpen}
        onClose={modalWrongNetwork.onClose}
        network={NETWORK_NAME}
      />
    </>
  )
}
