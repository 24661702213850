import { AwaitConsumer } from '@dev-plus-plus/react-await'
import { Box, HStack } from '@illuvium/illuvium-design'
import React from 'react'
import { useRecoilState } from 'recoil'
import { Button, ButtonProps } from 'src/components/button'
import { GasIcon } from 'src/components/icons'
import { useSetupContext } from 'src/components/setup-provider'
import { SkeletonLoader } from 'src/components/skeleton-loader'
import { useDisclosure } from 'src/hooks/useDisclosure'
import { gasTrackerAtom } from 'src/recoil/gas-tracker'

import { DropdownGasTracker } from './dropdown-gas-tracker'
import { IconText } from './styles'

export interface ButtonGasTrackerProps extends ButtonProps {
  isFooter?: boolean
}

export const ButtonGasTracker: React.FC<ButtonGasTrackerProps> = (props) => {
  const { isFooter, ...rest } = props

  const { handlerGasTracker } = useSetupContext()

  const [gasTracker] = useRecoilState(gasTrackerAtom)

  const dropdownGasTracker = useDisclosure()

  return (
    <Box position={'relative'}>
      <Button
        py={2}
        px={2}
        minW={17}
        onClick={dropdownGasTracker.onToggle}
        {...rest}
      >
        <AwaitConsumer
          loader={handlerGasTracker.loader}
          loadingView={<SkeletonLoader height={16} />}
        >
          <HStack>
            <Box mb={-1.5}>
              <GasIcon />
            </Box>

            <IconText>{gasTracker?.standard ?? '-'}</IconText>
          </HStack>
        </AwaitConsumer>
      </Button>

      <DropdownGasTracker
        isOpen={dropdownGasTracker.isOpen}
        onClose={dropdownGasTracker.onClose}
        isFooter={isFooter}
      />
    </Box>
  )
}
