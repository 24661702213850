import { ToastBar } from '@illuvium/illuvium-design'
import React from 'react'
import { toast, Toaster } from 'react-hot-toast'

export const ToasterCustom: React.FC = () => (
  <Toaster
    position={'top-center'}
    gutter={16}
    containerStyle={{ top: 22, zIndex: 100000 }}
    toastOptions={{
      duration: 6000,
    }}
  >
    {({ id, type, duration, visible, message }) => (
      <ToastBar
        type={type}
        duration={duration}
        visible={String(visible) === 'true'} // Toaster library issue
        onDismiss={() => toast.dismiss(id)}
      >
        {message}
      </ToastBar>
    )}
  </Toaster>
)
