import { DataStorage } from '@dev-plus-plus/storage-transformer'
import { DataType } from '@dev-plus-plus/storage-transformer/dist/DataResult'
import React from 'react'

export function useDataStorage<T = unknown>(
  key: string,
  dataType?: DataType<T>
) {
  const storage = DataStorage.bind(key).as<T>(dataType)
  return React.useMemo(() => storage, [storage])
}
