import { Box, Button, Stack, toColor, toSpace } from '@illuvium/illuvium-design'
import { SmallSpinnerProps } from 'src/components/small-spinner'
import { defaultTheme } from 'src/styles/theme'
import { withoutDomProps } from 'src/utils/helpers'
import styled from 'styled-components'

const { palette, button, border, breakpoints } = defaultTheme

export const Container = styled.div`
  position: relative;
`

interface IDropdownAccountBox {
  isFooter?: boolean
}

export const DropdownAccountBox = styled(Stack).withConfig(
  withoutDomProps('isFooter')
)<IDropdownAccountBox>`
  user-select: none;
  background-color: ${palette.containerBg.main};
  border: ${border.size} solid ${palette.common.black};
  box-sizing: border-box;
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.24);
  border-radius: ${border.radius};
  z-index: 1;

  position: fixed;
  top: ${toSpace(24)};
  left: ${toSpace(2)};
  right: ${toSpace(2)};
  max-height: calc(100vh - 11rem);

  &:before {
    content: ' ';
    position: absolute;
    top: -7px;
    right: 4rem;
    height: 0.75rem;
    width: 0.75rem;
    transform: rotate(225deg);
    background: inherit;
    border-bottom: inherit;
    border-right: inherit;
  }

  @media screen and (min-width: ${breakpoints.medium}) {
    position: absolute;
    left: auto;
    right: 0;
    bottom: auto;
    top: ${toSpace(19)};
    max-width: 400px;
    max-height: calc(100vh - 6.5rem);
  }
`

export const DropdownContent = styled(Box)`
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: ${toSpace(2)};
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0.25rem;
    background: transparent;
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      background: ${palette.lightBlue.main}80;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: ${palette.lightBlue.main};
    }
  }
`

export const DropdownBackdrop = styled(Box)`
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1;
`

export const AddressBadge = styled(Stack)`
  border: 1px solid transparent;
  border-radius: ${button.radius};
  background-image: linear-gradient(
      ${palette.inputBg.main},
      ${palette.inputBg.main}
    ),
    ${palette.gradients.holo.main};
  background-origin: border-box;
  background-clip: padding-box, border-box;
  padding: ${toSpace(2)};
  font-weight: bold;
`

export const RemoveButton = styled(Button)`
  transition: all 300ms ease-in-out;
  outline: none;
  background: transparent;
  border: none;
  cursor: pointer;
  color: ${palette.common.white};
  border-radius: 100px;
  height: ${toSpace(8)};
  min-width: ${toSpace(8)};
  padding: ${toSpace(2)};
  letter-spacing: 0;
  flex: 0;

  &:hover {
    background: ${palette.pink.main}64;
  }

  &:focus-visible {
    box-shadow: 0 0 0 2px ${palette.pink.main};
  }
`
export const GhostButton = styled.button`
  transition: all 300ms ease-in-out;
  outline: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
  user-select: none;
  border-radius: 4px;
  padding: ${toSpace(1)};
  color: ${toColor('lightBlue.main')};

  &:hover {
    background-color: ${palette.pink.main}32;
  }

  &:focus-visible {
    box-shadow: 0 0 0 2px ${palette.pink.main};
  }
`

export const LoadingSpinnerContainer = styled.div<SmallSpinnerProps>`
  transform: scale(0.3);
  margin: -30px;
`
