import { Box, Button, Stack, theme, toSpace } from '@illuvium/illuvium-design'
import { withoutDomProps } from 'src/utils/helpers'
import styled, { css } from 'styled-components'

const { palette, border } = theme

interface ModalContainerTypes {
  isBorderHolo?: boolean
}

const holoBorders = css`
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 8px;
    padding: 2px;
    margin: -1px;
    background: ${palette.gradients.holo.main};
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
  }
`

export const ModalWrapper = styled(Box)`
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 10001;
  inset: 0;
  max-width: none;
  transform: none;
  margin: 0 !important;
  padding: 0;
  backdrop-filter: blur(5px);
`

export const ModalContainer = styled(Box)`
  position: relative;
  flex: 1;
  padding: ${toSpace(2)};
  display: flex;
  flex-direction: column;
`

export const ModalContent = styled(Stack).withConfig(
  withoutDomProps('isBorderHolo')
)<ModalContainerTypes>`
  position: relative;
  margin: auto;
  background-color: ${palette.containerBg.main};
  border: ${border.size} solid ${palette.common.black};
  box-sizing: border-box;
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.24);
  border-radius: ${border.radius};
  ${(props) => props.isBorderHolo && holoBorders}
`

export const ModalBackdrop = styled(Box)`
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.4);
`

export const RemoveButton = styled(Button)`
  transition: all 300ms ease-in-out;
  outline: none;
  background: transparent;
  border: none;
  cursor: pointer;
  color: ${palette.common.white};
  border-radius: 100px;
  height: ${toSpace(8)};
  min-width: ${toSpace(8)};
  padding: ${toSpace(2)};
  letter-spacing: 0;
  flex: 0;

  &:hover {
    background: ${palette.pink.main}64;
  }

  &:focus-visible {
    box-shadow: 0 0 0 2px ${palette.pink.main};
  }
`
