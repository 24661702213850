import { GasPrices, gasTrackerAtom } from 'src/recoil/gas-tracker'
import { fetchGasPrice } from 'src/services/etherscan-service'

import { useSyncAtom } from './useSyncAtom'

export function useSetupGasTracker(refreshTimer?: number) {
  const populate = async () => {
    const { result } = await fetchGasPrice()

    const {
      FastGasPrice: fast,
      ProposeGasPrice: standard,
      SafeGasPrice: slow,
    } = result

    return { fast, standard, slow } as GasPrices
  }

  const { isFirstLoad, handler, refresh } = useSyncAtom(
    gasTrackerAtom,
    populate,
    refreshTimer
  )

  return {
    isFirstGasTracker: isFirstLoad,
    handlerGasTracker: handler,
    refreshGasTracker: refresh,
  }
}
