import React from 'react'
import {
  SwitchFragment,
  SwitchTransitionEffect,
} from 'src/components/switch-transition-effect'

export interface TransitionEffectProps {
  activeView?: React.ReactNode
  inactiveView?: React.ReactNode
  isActive?: boolean
  timeout?: number
  classNames?: string
}

export const TransitionEffect: React.FC<TransitionEffectProps> = (props) => {
  const {
    children,
    activeView,
    inactiveView,
    isActive,
    timeout = 400,
    classNames = 'fade',
  } = props

  return (
    <SwitchTransitionEffect
      value={isActive ? 1 : 0}
      classNames={classNames}
      timeout={timeout}
    >
      <SwitchFragment value={1} children={children ?? activeView} />

      {inactiveView && <SwitchFragment value={0} children={inactiveView} />}
    </SwitchTransitionEffect>
  )
}
