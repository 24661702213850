import { createStyledComponent } from '@illuvium/illuvium-design'
import { defaultTheme } from 'src/styles/theme'
import { withoutDomProps } from 'src/utils/helpers'
import styled, { css, keyframes } from 'styled-components'

import { ButtonProps } from './button'

const { palette } = defaultTheme

const Button = createStyledComponent('button')

export const createBgFrame = (ratio = 0, isHolo = false, isActive = false) => {
  const r = Math.max(Math.min(ratio, 1), -1)
  const pwd = (val: number) => 1 + val * r

  if (isHolo) {
    return css`
      background-image: linear-gradient(
          180deg,
          hsla(246, 45%, ${33 * pwd(0.2)}%, 1) 0.06%,
          hsla(256, 68.2%, ${8.6 * pwd(0.2)}%, 0.88) 95.37%
        ),
        linear-gradient(
          ${r * 65 + 270}deg,
          #08ce01 0%,
          #ceef00 17.19%,
          #51f980 34.38%,
          #2d51ed 50%,
          #0060f1 67.19%,
          #f100d9 83.33%,
          #9a24ec 100%
        );
    `
  } else if (isActive) {
    return css`
      background-image: linear-gradient(
          180deg,
          hsl(246, 44.7%, ${50 * pwd(0.2)}%) 0.06%,
          hsl(256, 60.5%, ${30 * pwd(0.2)}%) 95.37%
        ),
        linear-gradient(hsl(246, 95%, 71%), hsl(272, 73%, 56%));
    `
  } else {
    return css`
      background-image: linear-gradient(
          hsl(246, 44.7%, ${33.3 * pwd(0.2)}%),
          hsl(256, 60.5%, ${15.9 * pwd(0.2)}%)
        ),
        linear-gradient(hsl(247, 53.3%, 55.5%), hsl(272, 59.1%, 36.5%));
    `
  }
}

export const hoverAnimation = (
  isHolo: boolean,
  isActive: boolean,
  reverse: boolean
) => keyframes`
  0% {
    ${createBgFrame(Math.abs(+reverse), isHolo, isActive)};
  }
  10% {
    ${createBgFrame(Math.abs(+reverse - 0.1), isHolo, isActive)};
  }
  20% {
    ${createBgFrame(Math.abs(+reverse - 0.2), isHolo, isActive)};
  }
  30% {
    ${createBgFrame(Math.abs(+reverse - 0.3), isHolo, isActive)};
  }
  40% {
    ${createBgFrame(Math.abs(+reverse - 0.4), isHolo, isActive)};
  }
  50% {
    ${createBgFrame(Math.abs(+reverse - 0.5), isHolo, isActive)};
  }
  60% {
    ${createBgFrame(Math.abs(+reverse - 0.6), isHolo, isActive)};
  }
  70% {
    ${createBgFrame(Math.abs(+reverse - 0.7), isHolo, isActive)};
  }
  80% {
    ${createBgFrame(Math.abs(+reverse - 0.8), isHolo, isActive)};
  }
  90% {
    ${createBgFrame(Math.abs(+reverse - 0.9), isHolo, isActive)};
  }
  100% {
    ${createBgFrame(Math.abs(+reverse - 1), isHolo, isActive)};
  }
`

export const activeAnimation = (
  isHolo: boolean,
  isActive: boolean
) => keyframes`
  0% {
    ${createBgFrame(1, isHolo, isActive)};
  }
  10% {
    ${createBgFrame(0.8, isHolo, isActive)};
  }
  20% {
    ${createBgFrame(0.6, isHolo, isActive)};
  }
  30% {
    ${createBgFrame(0.4, isHolo, isActive)};
  }
  40% {
    ${createBgFrame(0.2, isHolo, isActive)};
  }
  50% {
    ${createBgFrame(0, isHolo, isActive)};
  }
  60% {
    ${createBgFrame(-0.2, isHolo, isActive)};
  }
  70% {
    ${createBgFrame(-0.4, isHolo, isActive)};
  }
  80% {
    ${createBgFrame(-0.6, isHolo, isActive)};
  }
  90% {
    ${createBgFrame(-0.8, isHolo, isActive)};
  }
  100% {
    ${createBgFrame(-1, isHolo, isActive)};
  }
`

export const StyledButton = styled(Button).withConfig(
  withoutDomProps('isActive', 'isHolo', 'disabled')
)<ButtonProps>`
  transition: all 300ms ease-in-out;
  position: relative;

  cursor: pointer;
  outline: 0 solid transparent;

  color: ${palette.common.white};
  font-weight: bold;
  text-align: center;
  text-transform: capitalize;
  user-select: none;

  border-radius: 5px;
  border: 1px solid transparent;

  ${({ isHolo, isActive, disabled }) =>
    !disabled &&
    css`
      ${createBgFrame(0, isHolo, isActive)};
      animation-name: ${hoverAnimation(isHolo, isActive, true)};
      animation-duration: 200ms;
      animation-fill-mode: forwards;
      animation-timing-function: ease-in-out;
    `}

  background-origin: border-box;
  background-clip: padding-box, border-box;
  box-sizing: border-box;

  text-shadow: 0 2px 0 rgba(105, 91, 91, 0.24);

  &:before {
    content: ' ';
    position: absolute;
    inset: 0;
    border-radius: 4px;
    border: 1px solid transparent;
    background-image: linear-gradient(rgba(39, 34, 89, 1), rgba(18, 11, 40, 1));
    background-origin: border-box;
    mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: source-out;
    mask-composite: exclude;
  }

  &:hover {
    ${({ isHolo, isActive }) => css`
      animation-name: ${hoverAnimation(isHolo, isActive, false)};
      animation-duration: 300ms;
      animation-fill-mode: forwards;
      animation-timing-function: ease-in-out;
    `}
  }

  &:active {
    ${({ isHolo, isActive }) => css`
      animation-name: ${activeAnimation(isHolo, isActive)};
      animation-duration: 200ms;
      animation-fill-mode: forwards;
      animation-timing-function: ease-in-out;
    `}
  }

  &:focus-visible {
    outline-width: 3px;
    outline-color: rgba(171, 84, 244, 0.25);
  }

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      opacity: 0.12;
      border: 1px solid ${palette.common.white};
      background: transparent;
    `}
`
