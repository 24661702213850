import {
  Box,
  HStack,
  Stack,
  StackProps,
  VStack,
} from '@illuvium/illuvium-design'
import React from 'react'

export interface HomeIntroProps extends StackProps {}

export const HomeIntro: React.FC<HomeIntroProps> = (props) => {
  const { ...rest } = props

  return (
    <Stack {...rest}>
      <VStack alignItems={'center'}>
        <HStack alignItems={'center'}>
          <Box
            as={'img'}
            src={'/images/icons/weth.svg'}
            alt={'WETH'}
            w={5}
            mr={4}
            mb={-2}
          />

          <Box
            fontSize={{ base: 8, md: 10 }}
            fontWeight={'bold'}
            color={'common.white'}
            position={'relative'}
            left={-4}
            as={'h1'}
          >
            Claim WETH
          </Box>
        </HStack>

        <Box
          textAlign={'center'}
          fontSize={4}
          color={'common.white'}
          as={'p'}
          width={{ base: '100%', md: '500px' }}
          py={4}
        >
          If the connected wallet is eligible to claim WETH, you can use the
          form below to call the mint contract and claim.
        </Box>
      </VStack>
    </Stack>
  )
}
