import { toSpace } from '@illuvium/illuvium-design'
import { NavLink } from 'react-router-dom'
import { defaultTheme } from 'src/styles/theme'
import styled from 'styled-components/macro'

const { spacing, palette, nav, breakpoints } = defaultTheme

export const StyledNav = styled.nav`
  background-color: ${palette.inputBg.main};
  position: sticky;
  top: 0;

  width: 100%;
  height: ${nav.height};
  z-index: 1;
`

export const LogoContainer = styled.div`
  padding-right: ${spacing.base * 4}px;
  svg {
    max-width: 156px;
  }
  @media screen and (min-width: ${breakpoints.large}) {
    padding-right: 0;
    width: 156px;
  }
`

export const StyledLink = styled(NavLink)`
  transition: all 400ms ease-in-out;
  padding: ${toSpace(4)} ${toSpace(1)};
  color: ${palette.lightBlue.main};
  text-decoration: none;

  &:hover {
    color: ${palette.common.white};
  }

  &.active {
    color: ${palette.common.white};
  }
`
