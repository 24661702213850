import { Location } from 'history'
import nprogress from 'nprogress'
import React from 'react'
import { Route, Switch, useLocation } from 'react-router'
import { CSSTransition, SwitchTransition } from 'react-transition-group'

import { Home } from './pages/home'

export const RoutesMap: React.FC = () => {
  const location = useLocation<Record<'background', Location>>()
  const state = location.state
  const { background } = state ?? {}

  const [prevLocation, setPrevLocation] = React.useState<string>()

  React.useEffect(() => {
    nprogress.configure({ showSpinner: false })
  }, [])

  React.useEffect(() => {
    setPrevLocation(location.key)
    nprogress.start()
  }, [location])

  React.useEffect(() => {
    nprogress.done()
  }, [prevLocation])

  return (
    <SwitchTransition mode={'out-in'}>
      <CSSTransition
        key={location.pathname}
        classNames={'fade-up'}
        timeout={400}
        unmountOnExit={true}
        appear={true}
      >
        <Switch location={background ?? location}>
          <Route path={'/'} component={Home} exact={true} />
        </Switch>
      </CSSTransition>
    </SwitchTransition>
  )
}
