import { atom } from 'recoil'

export interface Blockchain {
  blockNumber: number
}

const BLOCKCHAIN_DEFAULT = {
  blockNumber: 0,
}

const blockchainAtom = atom<Blockchain>({
  key: 'blockchainAtom',
  default: BLOCKCHAIN_DEFAULT,
})

export { blockchainAtom }
