import { AwaitConsumer } from '@dev-plus-plus/react-await'
import { Box, HStack, Stack } from '@illuvium/illuvium-design'
import React from 'react'
import { useRecoilState } from 'recoil'
import { Divider } from 'src/components/divider'
import { useSetupContext } from 'src/components/setup-provider'
import { SkeletonLoader } from 'src/components/skeleton-loader'
import { TransitionEffect } from 'src/components/transition-effect'
import { gasTrackerAtom } from 'src/recoil/gas-tracker'

import { DropdownBackdrop, DropdownGasPrice } from './styles'

interface DropdownGasTrackerProps {
  isOpen?: boolean
  onClose?: () => void
  isFooter?: boolean
}

export const DropdownGasTracker: React.FC<DropdownGasTrackerProps> = (
  props
) => {
  const { isOpen, onClose, isFooter, ...rest } = props

  const { handlerGasTracker } = useSetupContext()

  const [gasTracker] = useRecoilState(gasTrackerAtom)

  if (!gasTracker) {
    return <React.Fragment />
  }

  const { fast, standard, slow } = gasTracker

  const SlotGasItem = (props: {
    label: string
    value: string
    icon: string
  }) => (
    <HStack>
      <Box
        as={'img'}
        src={`/images/icons/${props.icon}`}
        alt={'icon'}
        height={6}
      />

      <Box flex={1} fontSize={4} color={'lightBlue.main'}>
        {props.label}
      </Box>

      <Box fontSize={4} fontWeight={'bold'} color={'common.white'}>
        {props.value} GWEI
      </Box>
    </HStack>
  )

  return (
    <>
      <TransitionEffect isActive={isOpen} classNames={'fade'}>
        <DropdownBackdrop onClick={() => onClose?.()} />
      </TransitionEffect>

      <TransitionEffect
        isActive={isOpen}
        classNames={isFooter ? 'fade-up' : 'fade-down'}
      >
        <DropdownGasPrice isFooter={isFooter} {...rest}>
          <Stack spacing={4}>
            <Box fontWeight={'bold'} fontSize={4} textAlign={'center'}>
              Gas price estimate
            </Box>

            <Divider variant={'holo'} />

            <Box fontSize={3.5} color={'lightBlue.main'}>
              These values change based on how busy the Ethereum network is.
            </Box>

            <AwaitConsumer
              loader={handlerGasTracker.loader}
              loadingView={<SkeletonLoader height={18} />}
            >
              <SlotGasItem icon={'fast.png'} label={'Fast'} value={fast} />
            </AwaitConsumer>

            <AwaitConsumer
              loader={handlerGasTracker.loader}
              loadingView={<SkeletonLoader height={18} />}
            >
              <SlotGasItem
                icon={'standard.png'}
                label={'Standard'}
                value={standard}
              />
            </AwaitConsumer>

            <AwaitConsumer
              loader={handlerGasTracker.loader}
              loadingView={<SkeletonLoader height={18} />}
            >
              <SlotGasItem icon={'slow.png'} label={'Slow'} value={slow} />
            </AwaitConsumer>
          </Stack>
        </DropdownGasPrice>
      </TransitionEffect>
    </>
  )
}
