import { defaultTheme } from 'src/styles/theme'
import { StyledConfig } from 'styled-components'

const { currency } = defaultTheme

export const getEtherscanUrl = (url: string | undefined) => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    return `http://rinkeby.etherscan.io/${url}`
  } else {
    return `http://etherscan.io/${url}`
  }
}

export const getTxExplorerUrl = (url: string | undefined) => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    return `http://rinkeby.etherscan.io/tx/${url}`
  } else {
    return `http://etherscan.io/tx/${url}`
  }
}

export const round = (number: any, decimals?: number) => {
  const decimalPlaces = decimals || currency.decimals
  const factorOfTen = Math.pow(10, decimalPlaces)
  return Math.round(number * factorOfTen) / factorOfTen
}

declare global {
  interface Number {
    formatCurrency: (sym?: '$' | '', dec?: number) => string
  }
}

// eslint-disable-next-line no-extend-native
Number.prototype.formatCurrency = function (sym = '$', dec = 2) {
  return new Intl.NumberFormat('en-US', {
    maximumFractionDigits: dec,
    ...(sym
      ? {
          style: 'currency',
          currency: sym ? 'USD' : '',
        }
      : {}),
  }).format(+this)
}

const SI_PREFIXES_CENTER_INDEX = 8

const siPrefixes: readonly string[] = [
  'y',
  'z',
  'a',
  'f',
  'p',
  'n',
  'μ',
  'm',
  '',
  'k',
  'M',
  'B',
  'T',
  'P',
  'E',
  'Z',
  'Y',
]

export const getSiPrefixedNumber = (
  number: number,
  decimals = currency.decimals
): string => {
  if (number === 0) return number.toString()
  const EXP_STEP_SIZE = 3
  const base = Math.floor(Math.log10(Math.abs(number)))
  const siBase = (base < 0 ? Math.ceil : Math.floor)(base / EXP_STEP_SIZE)
  const prefix = siPrefixes[siBase + SI_PREFIXES_CENTER_INDEX]

  // return number as-is if no prefix is available
  if (siBase === 0) return number.toString()

  // We're left with a number which needs to be divided by the power of 10e[base]
  // This outcome is then rounded two decimals and parsed as float to make sure those
  // decimals only appear when they're actually required (10.0 -> 10, 10.90 -> 19.9, 10.01 -> 10.01)
  const baseNumber = parseFloat(
    (number / Math.pow(10, siBase * EXP_STEP_SIZE)).toFixed(decimals)
  )
  return `${baseNumber.toFixed(decimals)}${prefix}`
}

export const { format: toUSD } = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

export const { format: formatAmount } = new Intl.NumberFormat('en-US', {
  currency: 'USD',
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
})

export const capitalize = (str: string): string =>
  str
    ?.split('')
    .map((e, i) => (i === 0 ? e.toUpperCase() : e))
    .join('')

export const roundZeroToFirstNonZero = (number: any) =>
  number.toFixed(18).match(/^-?\d*\.?0*\d{0,2}/)[0]

export const truncateAddress = (account: string) => {
  if (account) {
    const first = account.substring(0, 6)
    const last = account.substring(38, 42)
    return `${first}...${last}`
  } else {
    return account
  }
}

export function fallbackCopyToClipboard(text: string) {
  const textArea = document.createElement('textarea')
  textArea.value = text

  // Avoid scrolling to bottom
  textArea.style.top = '0'
  textArea.style.left = '0'
  textArea.style.position = 'fixed'

  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  document.execCommand('copy')
  document.body.removeChild(textArea)
}

export async function copyToClipboard(text: string) {
  if (navigator.clipboard) {
    await navigator.clipboard.writeText(text)
  } else {
    fallbackCopyToClipboard(text)
  }
}

export function withoutDomProps<O extends Record<string, unknown>>(
  ...props: string[]
) {
  return {
    shouldForwardProp: (prop) => !props.includes(prop.toString()),
  } as StyledConfig<O>
}
