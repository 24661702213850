import { Box, HStack, StackProps } from '@illuvium/illuvium-design'
import React from 'react'
import { ButtonGasTracker } from 'src/components/button-gas-tracker'
import { ButtonWallet } from 'src/components/button-wallet'

export interface FooterMobileProps extends StackProps {}

export const FooterMobile: React.FC<FooterMobileProps> = (props) => {
  const { ...rest } = props

  return (
    <HStack
      px={2}
      pt={2}
      pb={6}
      position={'sticky'}
      bottom={0}
      insetX={0}
      zIndex={1}
      bg={'inputBg.main'}
      {...rest}
    >
      <ButtonGasTracker isFooter={true} />

      <Box flex={1} />

      <ButtonWallet isFooter={true} useSoftLoader={true} />
    </HStack>
  )
}
