import {
  Box,
  HStack,
  LoadingSpinner,
  Stack,
  StackProps,
} from '@illuvium/illuvium-design'
import moment from 'moment'
import React from 'react'
import { Transaction } from 'src/recoil/transactions'

import { LoadingSpinnerContainer } from './styles'

export interface TransactionItemProps extends StackProps {
  tx: Transaction
}

export const TransactionItem: React.FC<TransactionItemProps> = (props) => {
  const { tx, ...rest } = props

  const { hash, type, status, label, tokenAmount, tokenSymbol, timestamp } = tx
  const { txHash, title } = tx // legacy

  const isPending = React.useMemo(() => status === 'pending', [status])
  const isSuccess = React.useMemo(() => status === 'success', [status])
  const isError = React.useMemo(() => status === 'error', [status])

  const icon = React.useMemo(() => {
    if (type === 'approve') {
      return 'stats.png'
    } else if (type === 'stake') {
      return 'download.png'
    } else if (type === 'claim') {
      return 'currency.png'
    }
  }, [type])

  return (
    <HStack spacing={4} minH={15} px={1} {...rest}>
      {icon ? (
        <Box as={'img'} src={`./images/icons/${icon}`} w={4} alt={'icon'} />
      ) : (
        <Box w={4} />
      )}

      <Stack spacing={1} flex={1}>
        <Box fontSize={3.5} fontWeight={'bold'} color={'lightBlue.main'}>
          {label ?? title}
        </Box>

        <Box fontSize={4.5} fontWeight={'bold'}>
          <div
            style={{
              color: isError ? 'rgba(255, 255, 255, 0.32)' : undefined,
            }}
          >
            <>{tokenAmount} </>
            <>{tokenSymbol || ''}</>
          </div>
        </Box>

        <HStack>
          {isPending ? (
            <>
              <Box fontSize={3}>
                <div style={{ color: '#FFBB54' }}>Pending</div>
              </Box>

              <Box fontSize={3} color={'lightBlue.main'}>
                |
              </Box>
            </>
          ) : (
            timestamp && (
              <>
                <Box fontSize={3}>
                  {moment(timestamp * 1000).format('hh:mma')}
                </Box>

                <Box fontSize={3} color={'lightBlue.main'}>
                  |
                </Box>

                <Box fontSize={3}>
                  {moment(timestamp * 1000).format('MM/DD/YYYY')}
                </Box>

                <Box fontSize={3} color={'lightBlue.main'}>
                  |
                </Box>
              </>
            )
          )}

          <Box
            as={'a'}
            href={`https://etherscan.io/tx/${hash ?? txHash}`}
            fontSize={3}
            color={'common.white'}
            target={'_blank'}
          >
            <HStack spacing={1}>
              <Box
                as={'img'}
                src={'./images/icons/etherscan.png'}
                w={3}
                alt={'icon'}
              />

              <span style={{ textDecoration: 'underline' }}>Etherscan</span>
            </HStack>
          </Box>
        </HStack>
      </Stack>

      {isPending ? (
        <HStack>
          <LoadingSpinnerContainer>
            <LoadingSpinner />
          </LoadingSpinnerContainer>
        </HStack>
      ) : isSuccess ? (
        <Box as={'img'} src={'./images/icons/check.png'} w={5} alt={'icon'} />
      ) : isError ? (
        <Stack
          direction={{ base: 'column', md: 'row' }}
          spacing={{ base: 1, md: 1 }}
          alignItems={'flex-end'}
        >
          <Box
            fontSize={{ base: 2.5, md: 3, lg: 3.5 }}
            mb={1}
            color={'common.red'}
          >
            Failed
          </Box>

          <Box as={'img'} src={'./images/icons/error.png'} w={5} alt={'icon'} />
        </Stack>
      ) : (
        <React.Fragment />
      )}
    </HStack>
  )
}
