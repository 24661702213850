import { Box, HStack, StackProps } from '@illuvium/illuvium-design'
import React from 'react'
import { CSSTransition, SwitchTransition } from 'react-transition-group'

import {
  ModalBackdrop,
  ModalContainer,
  ModalContent,
  ModalWrapper,
  RemoveButton,
} from './styles'

export interface ModalProps extends StackProps {
  title?: string
  isOpen?: boolean
  onClose?: () => void
  preventClosing?: boolean
  isBorderHolo?: boolean
  hideIconClose?: boolean
}

export const Modal: React.FC<ModalProps> = (props) => {
  const {
    children,
    title,
    isOpen,
    onClose,
    preventClosing,
    isBorderHolo,
    hideIconClose,
    ...rest
  } = props

  const close = React.useCallback(() => {
    if (!preventClosing) {
      onClose?.()
    }
  }, [onClose, preventClosing])

  // Lock body scroll
  React.useEffect(() => {
    document.documentElement.style.overflowY = isOpen ? 'hidden' : 'unset'

    return () => {
      document.documentElement.style.overflowY = 'unset'
    }
  }, [isOpen])

  return (
    <SwitchTransition mode={'out-in'}>
      <CSSTransition
        key={isOpen ? 1 : 0}
        classNames={'fade'}
        timeout={400}
        unmountOnExit={true}
        appear={true}
      >
        {isOpen ? (
          <ModalWrapper>
            <ModalContainer>
              <ModalBackdrop onClick={close} />

              <ModalContent
                w={{ base: 'full', md: 160 }}
                p={{ base: 4, md: 6 }}
                isBorderHolo={isBorderHolo}
                {...rest}
              >
                {title || !hideIconClose ? (
                  <HStack position={'relative'} mt={-2} mr={-2}>
                    <Box
                      fontSize={[3.5, 4, 5, 6]}
                      fontWeight={'bold'}
                      flex={1}
                      isTruncated={true}
                    >
                      {title}
                    </Box>

                    {!hideIconClose && !preventClosing ? (
                      <RemoveButton label={'✗'} onClick={close} />
                    ) : (
                      <React.Fragment />
                    )}
                  </HStack>
                ) : (
                  <React.Fragment />
                )}

                <Box flex={1}>{children}</Box>
              </ModalContent>
            </ModalContainer>
          </ModalWrapper>
        ) : (
          <React.Fragment />
        )}
      </CSSTransition>
    </SwitchTransition>
  )
}
