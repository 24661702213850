import { Box } from '@illuvium/illuvium-design'
import React from 'react'
import Skeleton, { SkeletonProps, SkeletonTheme } from 'react-loading-skeleton'

export interface SkeletonLoaderProps extends SkeletonProps {}

export const SkeletonLoader: React.FC<SkeletonLoaderProps> = (props) => {
  const { ...rest } = props

  return (
    <Box flex={1} alignSelf={'stretch'} position={'relative'} zIndex={-1}>
      <SkeletonTheme baseColor={'#ffffff20'} highlightColor={'#ffffff60'}>
        <Skeleton height={'calc(100% - 2px)'} {...rest} />
      </SkeletonTheme>
    </Box>
  )
}
