import { atom } from 'recoil'

export type TransactionType = 'approve' | 'stake' | 'claim'
export type TransactionStatus = 'pending' | 'success' | 'error'

export interface LegacyTransaction {
  txHash: string
  title: string
  status: TransactionStatus
}

export type Transaction = {
  hash: string
  type: TransactionType
  status: TransactionStatus
  label?: string
  tokenAmount?: number
  tokenSymbol?: string
  timestamp?: number
} & Partial<LegacyTransaction>

export const transactionsAtom = atom<Transaction[] | null>({
  key: 'transactionsAtom',
  default: null,
})
