import { createStyledComponent, HStack } from '@illuvium/illuvium-design'
import { defaultTheme } from 'src/styles/theme'
import { withoutDomProps } from 'src/utils/helpers'
import styled from 'styled-components'

const { palette } = defaultTheme

const Button = createStyledComponent('button')

export const StyledSelectNavbar = styled(HStack)`
  background: rgba(255, 255, 255, 0.09);
  border-radius: 4px;
`

export interface IButtonSelect {
  isActive?: boolean
}

export const ButtonSelect = styled(Button).withConfig(
  withoutDomProps('isActive')
)<IButtonSelect>`
  transition: all 300ms ease-in-out;
  position: relative;

  cursor: pointer;
  outline: 0 solid transparent;

  color: ${({ isActive }) =>
    isActive ? palette.common.white : 'rgba(255, 255, 255, 0.35)'};
  text-transform: capitalize;
  font-weight: bold;

  border: none;
  border-radius: 4px;
  background: ${({ isActive }) =>
    isActive ? 'rgba(255, 255, 255, 0.09)' : 'transparent'};

  &:hover {
    background: rgba(255, 255, 255, 0.12);
  }

  &:active {
    background: rgba(255, 255, 255, 0.06);
  }

  &:focus-visible {
    outline-width: 2px;
    outline-color: ${palette.pink.main}64;
  }
`
