import { axiosT } from '@dev-plus-plus/axios-transformer'
import { toast } from 'react-hot-toast'
import { ETHERSCAN_API_URL, ETHERSCAN_KEY } from 'src/utils/constants'

export interface GasPrice {
  result: {
    FastGasPrice: string
    ProposeGasPrice: string
    SafeGasPrice: string
  }
}

export const fetchGasPrice = async () => {
  try {
    return await axiosT
      .get(ETHERSCAN_API_URL)
      .addParams('module', 'gastracker')
      .addParams('action', 'gasoracle')
      .addParams('apikey', ETHERSCAN_KEY)
      .as<GasPrice>()
      .fetchData()
  } catch (e: any) {
    toast.error(e.message)

    throw e
  }
}
