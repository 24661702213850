import { atom } from 'recoil'
import { ConnectorNames } from 'src/constants/wallet'

export interface Wallet {
  address: string
  connectorName: string
  iconName: string
}

export const connectorNameAtom = atom<ConnectorNames | null>({
  key: 'connectorNameAtom',
  default: null,
})

export const walletAtom = atom<Wallet | null>({
  key: 'walletAtom',
  default: null,
})
