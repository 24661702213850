import '@dev-plus-plus/react-await/styles/effect.css'
import 'react-loading-skeleton/dist/skeleton.css'
import 'nprogress/nprogress.css'

import { AwaitProvider } from '@dev-plus-plus/react-await'
import { Web3Provider } from '@ethersproject/providers'
import { LoadingSpinner } from '@illuvium/illuvium-design'
import { Web3ReactProvider } from '@web3-react/core'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { RecoilRoot } from 'recoil'
import { Layout } from 'src/components/layout'
import { SetupProvider } from 'src/components/setup-provider'
import { ToasterCustom } from 'src/components/toaster-custom'
import { RoutesMap } from 'src/routes-map'
import { GlobalStyles } from 'src/styles/global-styles'
import { defaultTheme } from 'src/styles/theme'
import { ThemeProvider } from 'styled-components'

function getLibrary(provider: any): Web3Provider {
  const library = new Web3Provider(provider, 'any')
  library.pollingInterval = 12000
  return library
}

ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <Web3ReactProvider getLibrary={getLibrary}>
        <ThemeProvider theme={defaultTheme}>
          <AwaitProvider defaultLoadingView={<LoadingSpinner />}>
            <GlobalStyles />

            <Router>
              <SetupProvider>
                <Layout>
                  <RoutesMap />
                </Layout>
              </SetupProvider>
            </Router>

            <ToasterCustom />
          </AwaitProvider>
        </ThemeProvider>
      </Web3ReactProvider>
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById('root')
)
