import { Box } from '@illuvium/illuvium-design'
import { defaultTheme } from 'src/styles/theme'
import { withoutDomProps } from 'src/utils/helpers'
import styled, { css } from 'styled-components'

import { CardProps } from './card'

const { palette } = defaultTheme

export const StyledCard = styled(Box).withConfig(
  withoutDomProps(
    'isShiny',
    'hasBlurEffect',
    'isFaded',
    'isActive',
    'isPending'
  )
)<CardProps>`
  position: relative;

  color: ${palette.containerBg.contrastText};

  border-radius: ${({ rounded }) => rounded ?? '8px'};
  border: ${({ isShiny }) =>
    isShiny ? 'none' : '1px solid rgba(255, 255, 255, 0.1)'};
  background-image: ${({ isShiny }) =>
    isShiny
      ? `linear-gradient(
    rgba(127, 204, 237, 0.2) 0%,
    rgba(161, 104, 255, 0.31) 100%
  )`
      : `linear-gradient(
    rgba(119, 214, 255, 0.08) 0%,
    rgba(202, 169, 255, 0.08) 100%
  )`};

  ${({ hasBlurEffect }) =>
    hasBlurEffect ? 'backdrop-filter: blur(5px)' : undefined};

  ${({ isFaded }) => (isFaded ? 'opacity: 0.3' : undefined)};

  ${({ isPending }) =>
    isPending &&
    css`
      background: linear-gradient(
        180deg,
        rgba(127, 204, 237, 0.2) 0%,
        rgba(104, 119, 255, 0.31) 100%
      );
      box-shadow: 0px 8px 8px rgba(171, 84, 244, 0.16);
    `};

  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.08);

  &:after {
    z-index: -1;
    content: ${({ isShiny }) => (isShiny ? '" "' : 'none')};
    position: absolute;
    inset: 0;
    border-radius: ${({ rounded }) => rounded ?? '8px'};
    border: 1px solid transparent;
    background: linear-gradient(
        rgba(225, 194, 251, 0.22) 0%,
        rgba(171, 84, 244, 1) 100%
      )
      border-box;
    mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: source-out;
    mask-composite: exclude;

    ${({ isPending }) =>
      isPending &&
      css`
        background: linear-gradient(
          180deg,
          rgba(20, 33, 39, 0.2) 0%,
          rgba(104, 119, 255, 0.31) 100%
        );
      `})}
  }
`
