import {
  fortmatic,
  injected,
  portis,
  torus,
  trezor,
  walletconnect,
  walletlink,
} from 'src/utils/connectors'

export enum ConnectorNames {
  Browser = 'Browser Wallet',
  WalletConnect = 'WalletConnect',
  Trezor = 'Trezor',
  WalletLink = 'Coinbase Wallet',
  Fortmatic = 'Fortmatic',
  Portis = 'Portis',
  Torus = 'Torus',
}

export type ConnectorValues = {
  connector: any
  iconName: string
}

export const SUPPORTED_WALLETS: Record<ConnectorNames, ConnectorValues> = {
  [ConnectorNames.Browser]: {
    connector: injected,
    iconName: 'browser-wallet.svg',
  },
  [ConnectorNames.WalletConnect]: {
    connector: walletconnect,
    iconName: 'wallet-connect.svg',
  },
  [ConnectorNames.WalletLink]: {
    connector: walletlink,
    iconName: 'coinbase.svg',
  },
  [ConnectorNames.Trezor]: {
    connector: trezor,
    iconName: 'trezor.png',
  },
  [ConnectorNames.Fortmatic]: {
    connector: fortmatic,
    iconName: 'fortmatic.png',
  },
  [ConnectorNames.Portis]: {
    connector: portis,
    iconName: 'portis.png',
  },
  [ConnectorNames.Torus]: {
    connector: torus,
    iconName: 'torus.png',
  },
}
