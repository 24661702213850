import { defaultTheme } from 'src/styles/theme'
import styled from 'styled-components'

const { spacing, palette, breakpoints } = defaultTheme

export const Wrapper = styled.div`
  z-index: 1;

  @media screen and (min-width: ${breakpoints.medium}) {
    max-width: 400px;
  }

  @media screen and (min-width: ${breakpoints.large}) {
  }
`

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  word-break: break-word;
  text-overflow: ellipsis;
  max-height: 190px;
  overflow: hidden;

  p {
    margin: 0;
    margin-bottom: ${spacing.base * 2}px;

    word-break: keep-all;
    overflow: hidden;
    max-height: 190px;
  }

  a.notification-link {
    color: ${palette.lightBlue.main};
  }
`
