import { useAwait } from '@dev-plus-plus/react-await'
import { JsonRpcSigner, Web3Provider } from '@ethersproject/providers'
import { useWeb3React } from '@web3-react/core'
import React from 'react'
import { toast } from 'react-hot-toast'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { ConnectorNames, SUPPORTED_WALLETS } from 'src/constants/wallet'
import { transactionsAtom } from 'src/recoil/transactions'
import { connectorNameAtom, walletAtom } from 'src/recoil/wallet'
import { WALLET_CONNECTION } from 'src/utils/constants'

import { useDataStorage } from './useDataStorage'

export type UseWalletConnectorReturn = ReturnType<typeof useWalletConnector>

export function useWalletConnector(
  onConnect?: (account: string, signer: JsonRpcSigner) => void,
  deps?: React.DependencyList
) {
  const web3Context = useWeb3React<Web3Provider>()
  const { activate, deactivate, account, library } = web3Context

  // used for loading handler (react-await)
  const connectorHandler = useAwait('connect@useWalletConnector')

  // used for storage (localStorage)
  const connectorStorage = useDataStorage<ConnectorNames>(WALLET_CONNECTION)

  // used for state
  const [connectorName, setConnectorName] = useRecoilState(connectorNameAtom)
  const [wallet, setWallet] = useRecoilState(walletAtom)
  const setTransactions = useSetRecoilState(transactionsAtom)
  const [signer, setSigner] = React.useState<JsonRpcSigner>()

  const isConnected = React.useMemo(
    () => !!(account && signer),
    [account, signer]
  )

  React.useEffect(() => {
    if (isConnected && account && signer) {
      onConnect?.(account, signer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnected, account, ...(deps ?? [])])

  React.useEffect(() => {
    if (account && library && !signer) {
      setSigner(library.getSigner(account))
    }
  }, [account, library, signer])

  const connect = async (connectorName: ConnectorNames) => {
    try {
      const wallet = { ...SUPPORTED_WALLETS[connectorName] }

      await connectorHandler.controller.run(async () => {
        await activate(wallet.connector, undefined, true)
      })

      setConnectorName(connectorName)
      connectorStorage.save(connectorName)
    } catch (e: any) {
      toast.error(e.message)
      console.log(e)
    }
  }

  const disconnect = () => {
    deactivate()
    clearCache()
  }

  const clearCache = () => {
    connectorStorage.erase()
    setWallet(null)
    setTransactions(null)
  }

  const fixLegacyConnectorStorage = () => {
    const connector = localStorage.getItem(WALLET_CONNECTION)

    if (connector) {
      try {
        JSON.parse(connector)
      } catch {
        connectorStorage.save(connector as ConnectorNames)
      }
    }
  }

  return {
    connect,
    disconnect,
    clearCache,
    fixLegacyConnectorStorage,
    isConnected,
    wallet,
    signer,
    connectorName,
    connectorStorage,
    connectorHandler,
    web3Context,
  }
}
