import { HStack } from '@illuvium/illuvium-design'
import React from 'react'
import { ButtonGasTracker } from 'src/components/button-gas-tracker'
import { ButtonWallet } from 'src/components/button-wallet'
import { Container } from 'src/components/container'
import { IlvObeliskLogo, Logo } from 'src/components/icons'
import { useResponsive } from 'src/hooks/useResponsive'

import { LogoContainer, StyledNav } from './styles'

export const Navbar: React.FC = () => {
  const { isDesktop } = useResponsive()

  return (
    <StyledNav>
      <Container py={0} display={'flex'} alignSelf={'center'}>
        <HStack flex={1}>
          <HStack
            flex={1}
            direction={{ base: 'row', lg: 'row' }}
            spacing={{ base: 2, lg: 4 }}
            alignItems={'center'}
          >
            <LogoContainer>
              <a href="https://illuvium.io">
                {isDesktop ? <Logo /> : <IlvObeliskLogo />}
              </a>
            </LogoContainer>
          </HStack>

          {isDesktop && (
            <>
              <ButtonWallet useSoftLoader={true} />

              <ButtonGasTracker />
            </>
          )}
        </HStack>
      </Container>
    </StyledNav>
  )
}
