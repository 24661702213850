import { useRecoilState } from 'recoil'
import { transactionsAtom } from 'src/recoil/transactions'

import { useSafeLayoutEffect } from './useSafeLayoutEffect'
import { useTransactionStorage } from './useTransactionStorage'
import { useWalletConnector } from './useWalletConnector'

export const useSetupRecentTransactions = () => {
  const { isConnected, web3Context } = useWalletConnector()
  const { account } = web3Context

  const { getStoredTransactions } = useTransactionStorage()
  const [, setRecentTransactions] = useRecoilState(transactionsAtom)

  useSafeLayoutEffect(() => {
    if (isConnected) {
      const txs = getStoredTransactions()
      txs.reverse()
      setRecentTransactions(txs)
    } else {
      setRecentTransactions([])
    }
  }, [isConnected, account])
}
