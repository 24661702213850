import { Contract } from '@ethersproject/contracts'
import { JsonRpcSigner } from '@ethersproject/providers'
import ERC20Abi from 'src/abi/ERC20.json'
import WethMintAbi from 'src/abi/WethMint.json'
import { WETH_ID, WETH_MINT_ID } from 'src/utils/constants'

export const abiMapper = {
  [WETH_MINT_ID]: WethMintAbi,
  [WETH_ID]: ERC20Abi,
}

export const getContract = (contractId: string, signer: JsonRpcSigner) => {
  return new Contract(contractId, abiMapper[contractId], signer)
}
