import {
  Box,
  BoxProps,
  HStack,
  Stack,
  truncateAddress,
} from '@illuvium/illuvium-design'
import React from 'react'
import { toast } from 'react-hot-toast'
import { Button } from 'src/components/button'
import { Divider } from 'src/components/divider'
import { TransitionEffect } from 'src/components/transition-effect'
import { useTransactionStorage } from 'src/hooks/useTransactionStorage'
import { useWalletConnector } from 'src/hooks/useWalletConnector'
import { copyToClipboard } from 'src/utils/helpers'

import {
  AddressBadge,
  DropdownAccountBox,
  DropdownBackdrop,
  DropdownContent,
  GhostButton,
  RemoveButton,
} from './styles'
import { TransactionItem } from './transaction-item'

export interface DropdownAccountProps extends BoxProps {
  isOpen?: boolean
  onClose?: () => void
  isFooter?: boolean
}

export const DropdownAccount: React.FC<DropdownAccountProps> = (props) => {
  const { isOpen, onClose, isFooter, ...rest } = props

  const { wallet, disconnect } = useWalletConnector()
  const { recentTransactions, clearTransactions } = useTransactionStorage()

  if (!wallet) {
    return <React.Fragment />
  }

  const { iconName, connectorName, address } = wallet

  const copy = async () => {
    await copyToClipboard(address)
    toast.success('Copied to clipboard', { position: 'top-left' })
  }

  const disconnectEvent = () => {
    onClose?.()
    disconnect()
  }

  return (
    <>
      <TransitionEffect isActive={isOpen} classNames={'fade'}>
        <DropdownBackdrop onClick={() => onClose?.()} />
      </TransitionEffect>

      <TransitionEffect
        isActive={isOpen}
        classNames={isFooter ? 'fade-up' : 'fade-down'}
      >
        <DropdownAccountBox py={4} px={6} isFooter={isFooter} {...rest}>
          <Stack spacing={5} minH={0}>
            <HStack>
              <Box
                flex={1}
                fontSize={{ base: 4.5, md: 5, lg: 5.5 }}
                fontWeight={'bold'}
              >
                Account
              </Box>

              <RemoveButton label={'✗'} onClick={onClose} />
            </HStack>

            <Box>
              <Divider variant={'holo'} />
            </Box>

            <HStack>
              <Box
                as={'img'}
                src={`./images/wallets/${iconName}`}
                alt={'icon'}
                h={4}
              />

              <Box
                flex={1}
                fontSize={4}
                fontWeight={'bold'}
                color={'common.white'}
              >
                {connectorName}
              </Box>
            </HStack>

            <HStack>
              <AddressBadge>{truncateAddress(address)}</AddressBadge>

              <GhostButton onClick={copy}>
                <HStack>
                  <Box
                    as={'img'}
                    src={'/images/icons/copy-blue.png'}
                    w={4}
                    h={4}
                    alt={'copy'}
                  />

                  <Box as={'span'} fontSize={3.5}>
                    Copy
                  </Box>
                </HStack>
              </GhostButton>

              <Box flex={1} />

              <Button py={2} label={'Disconnect'} onClick={disconnectEvent} />
            </HStack>

            {!!recentTransactions?.length && (
              <>
                <Stack>
                  <HStack>
                    <Box flex={1} fontSize={4} fontWeight={'bold'}>
                      Recent Transactions
                    </Box>

                    <GhostButton onClick={clearTransactions}>
                      <HStack>
                        <Box as={'span'} fontSize={3.5}>
                          Clear
                        </Box>

                        <Box
                          as={'img'}
                          src={'/images/icons/error-blue.png'}
                          w={4}
                          h={4}
                          alt={'clear'}
                        />
                      </HStack>
                    </GhostButton>
                  </HStack>

                  <Divider variant={'basic'} />
                </Stack>

                <DropdownContent flex={1}>
                  <Stack h={'full'} spacing={4}>
                    {recentTransactions.map((tx) => (
                      <TransactionItem key={tx.hash} tx={tx} />
                    ))}
                  </Stack>
                </DropdownContent>
              </>
            )}
          </Stack>
        </DropdownAccountBox>
      </TransitionEffect>
    </>
  )
}
