import { FortmaticConnector } from '@web3-react/fortmatic-connector'
import { InjectedConnector } from '@web3-react/injected-connector'
import { LedgerConnector } from '@web3-react/ledger-connector'
import { PortisConnector } from '@web3-react/portis-connector'
import { TorusConnector } from '@web3-react/torus-connector'
import { TrezorConnector } from '@web3-react/trezor-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { WalletLinkConnector } from '@web3-react/walletlink-connector'
import { APP_NAME, NETWORK_ID } from 'src/utils/constants'

const CHAIN_ID = parseInt(NETWORK_ID)
const POLLING_INTERVAL = 12000
const RPC_URLS: { [chainId: number]: string } = {
  1: `https://eth-mainnet.alchemyapi.io/v2/${process.env.REACT_APP_ALCHEMY_KEY_PROD}`,
  4: `https://eth-rinkeby.alchemyapi.io/v2/${process.env.REACT_APP_ALCHEMY_KEY_RINKEBY}`,
}

export const injected = new InjectedConnector({
  supportedChainIds: [1, 4],
})

export const walletconnect = new WalletConnectConnector({
  rpc: { 1: RPC_URLS[1], 4: RPC_URLS[4] },
  qrcode: true,
  // pollingInterval: POLLING_INTERVAL
})

export const walletlink = new WalletLinkConnector({
  url: RPC_URLS[CHAIN_ID],
  appName: APP_NAME,
  supportedChainIds: [1, 4],
})

export const ledger = new LedgerConnector({
  chainId: CHAIN_ID,
  url: RPC_URLS[CHAIN_ID],
  pollingInterval: POLLING_INTERVAL,
})

export const trezor = new TrezorConnector({
  chainId: CHAIN_ID,
  url: RPC_URLS[CHAIN_ID],
  pollingInterval: POLLING_INTERVAL,
  manifestEmail: '',
  manifestAppUrl: '',
})

export const fortmatic = new FortmaticConnector({
  apiKey: process.env.REACT_APP_FORTMATIC_API_KEY as string,
  chainId: CHAIN_ID,
})

export const portis = new PortisConnector({
  dAppId: process.env.REACT_APP_PORTIS_DAPP_ID as string,
  networks: [1, 4],
})

export const torus = new TorusConnector({ chainId: CHAIN_ID })
