import { BoxProps } from '@illuvium/illuvium-design'
import React from 'react'

import { StyledCard } from './styles'

export interface CardProps extends BoxProps {
  isShiny?: boolean
  hasBlurEffect?: boolean
  isFaded?: boolean
  isPending?: boolean
}

export const Card: React.FC<CardProps> = (props) => {
  const { ...rest } = props

  return <StyledCard px={[2, 4, 6]} py={6} {...rest} />
}
