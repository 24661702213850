import { BoxProps, StyledValue } from '@illuvium/illuvium-design'
import React from 'react'

import { StyledText } from './styles'

export interface TextProps extends BoxProps {
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span' | 'div'
  variant?: 'header'
  lineHeight?: StyledValue<number | string>
}

export const Text: React.FC<TextProps> = (props) => {
  const { as = 'p', variant, ...rest } = props

  const isHeader = React.useMemo(() => variant === 'header', [variant])

  const fontSize = React.useMemo(() => {
    switch (as) {
      case 'h1':
        return [6, 8, 10, 12]
      case 'h2':
        return [5, 5, 6]
      case 'h3':
        return 4.5
      case 'h4':
        return 4
      case 'h5':
        return 3.5
      case 'h6':
        return 3
    }
  }, [as])

  const fontWeight = React.useMemo(() => {
    switch (as) {
      case 'h1':
        return 'bold'
      case 'h2':
        return 'bold'
      case 'h3':
        return 'bold'
      case 'h4':
        return 'bold'
      case 'h5':
        return 'bold'
      case 'h6':
        return 'bold'
    }
  }, [as])

  return (
    <StyledText
      as={as as any}
      pb={1}
      m={0}
      color={'common.white'}
      lineHeight={1.5}
      fontWeight={fontWeight}
      fontSize={fontSize}
      isHeader={isHeader}
      {...rest}
    />
  )
}
