import { BoxProps, LoadingSpinner } from '@illuvium/illuvium-design'
import React from 'react'

import { StyledSmallSpinner } from './styles'

export interface SmallSpinnerProps extends BoxProps {
  scale?: number
}

export const SmallSpinner: React.FC<SmallSpinnerProps> = (props) => {
  const { scale = 0.35, ...rest } = props

  return (
    <StyledSmallSpinner position={'absolute'} scale={scale} {...rest}>
      <LoadingSpinner />
    </StyledSmallSpinner>
  )
}
