import { AwaitConsumer } from '@dev-plus-plus/react-await'
import { Box, BoxProps, SimpleGrid, VStack } from '@illuvium/illuvium-design'
import React from 'react'
import { Button } from 'src/components/button'
import { SmallSpinner } from 'src/components/small-spinner'
import { ConnectorNames } from 'src/constants/wallet'
import { useWalletConnector } from 'src/hooks/useWalletConnector'

import { WalletBadge } from './wallet-badge'

export interface WalletCategoryHardwareProps extends BoxProps {
  onConnect?: (connectorName: ConnectorNames) => void
  onError?: (e: Error) => void
}

export const WalletCategoryHardware: React.FC<WalletCategoryHardwareProps> = (
  props
) => {
  const { onConnect, onError, ...rest } = props

  const { connect, connectorHandler } = useWalletConnector()

  const connectWallet = async (connectorName: ConnectorNames) => {
    try {
      await connect(connectorName)
      onConnect?.(connectorName)
    } catch (e: any) {
      onError?.(e)
      console.error(e)
    }
  }

  return (
    <VStack spacing={6} {...rest}>
      <Box textAlign={'center'} fontSize={3.5} color={'lightBlue.main'}>
        Ensure your Trezor wallet is connected
      </Box>

      <AwaitConsumer
        loader={connectorHandler.loader}
        loadingView={<SmallSpinner />}
      >
        <SimpleGrid flex={1} columns={1} spacing={4}>
          <Button onClick={() => connectWallet(ConnectorNames.Trezor)}>
            <WalletBadge px={8} icon={'trezor.png'} label={'Trezor'} />
          </Button>
        </SimpleGrid>
      </AwaitConsumer>
    </VStack>
  )
}
