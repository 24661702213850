import { useAwait } from '@dev-plus-plus/react-await'
import React from 'react'
import { RecoilState, useRecoilState } from 'recoil'

import { useInterval } from './useInterval'
import { useSafeLayoutEffect } from './useSafeLayoutEffect'

const DELAY = 1000
const HANDLER_SUFFIX = '@useSyncAtom'

export type UseSyncAtomReturn = ReturnType<typeof useSyncAtom>

export function useSyncAtom<T>(
  atom: RecoilState<T>,
  cb: (current: T) => Promise<T>,
  refreshTimer?: number
) {
  const handler = useAwait(atom.key + HANDLER_SUFFIX)

  const [item, setItem] = useRecoilState(atom)
  const [isFirstLoad, setIsFirstLoad] = React.useState(false)

  const populate = async () => {
    await handler.controller.run(() => cb(item), DELAY).then(setItem)
  }

  useInterval(() => {
    // call it every interval
    populate().then()
  }, refreshTimer ?? null)

  useSafeLayoutEffect(() => {
    // call it once when it loads
    populate().then(() => setIsFirstLoad(true))
  }, [])

  return { isFirstLoad, handler, refresh: populate }
}
