import { BigNumber } from 'ethers'
import { atom } from 'recoil'

export interface UserDataProps {
  index: number
  account: string
  balance: BigNumber
}

export const userDataAtom = atom<UserDataProps | undefined>({
  key: 'userDataAtom',
  default: undefined,
})
