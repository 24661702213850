import React from 'react'
import { useRecoilState } from 'recoil'
import { Transaction, transactionsAtom } from 'src/recoil/transactions'
import { RECENT_TX_KEY } from 'src/utils/constants'

import { useDataStorage } from './useDataStorage'
import { useWalletConnector } from './useWalletConnector'

export type UseTransactionStorageReturn = ReturnType<
  typeof useTransactionStorage
>

export const useTransactionStorage = () => {
  const { web3Context } = useWalletConnector()
  const { account } = web3Context

  // use either the new storage format (by address) or legacy storage (static key)
  const keyStorage = account ? `${RECENT_TX_KEY}__${account}` : RECENT_TX_KEY
  const txStorage = useDataStorage<Transaction[]>(keyStorage)
  const [recentTransactions, setRecentTransactions] =
    useRecoilState(transactionsAtom)

  const getStoredTransactions = React.useCallback(
    () => txStorage.load() ?? [],
    [txStorage]
  )

  const hasPendingTransaction = React.useMemo(
    () => recentTransactions?.some((tx) => tx.status === 'pending') ?? false,
    [recentTransactions]
  )

  const storeTransaction = (transaction: Transaction) => {
    const MAX_NO_ITEMS = 10
    const txs = getStoredTransactions()

    if (transaction.status !== 'pending') {
      txs.forEach((tx) => {
        if (tx.hash === transaction.hash) {
          tx.status = transaction.status
          tx.label = transaction.label
        }
      })
    } else if (getStoredTransactions.length >= MAX_NO_ITEMS) {
      txs.splice(0, 1)
      txs.push(transaction)
    } else {
      txs.push(transaction)
    }

    txStorage.save(txs)
    txs.reverse()
    setRecentTransactions(txs)
  }

  const clearTransactions = () => {
    txStorage.erase()
    setRecentTransactions([])
  }

  return {
    hasPendingTransaction,
    recentTransactions,
    getStoredTransactions,
    storeTransaction,
    clearTransactions,
  }
}
