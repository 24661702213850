import { StackProps } from '@illuvium/illuvium-design'
import React from 'react'

import { ButtonSelect, StyledSelectNavbar } from './styles'

export type SelectOption<T extends string | number | boolean = string> = {
  id: T
  label: React.ReactNode
}

export interface SelectNavbarProps<T extends string | number | boolean>
  extends StackProps {
  value?: SelectOption<T>
  onSelect?: (val: SelectOption<T>) => void
  options?: SelectOption<T>[]
}

export function SelectNavbar<T extends string | number | boolean = string>(
  props: SelectNavbarProps<T>
) {
  const { value, onSelect, options = [], ...rest } = props

  const isActive = React.useCallback(
    (option: SelectOption<T>) => option.id === value?.id,
    [value?.id]
  )

  return (
    <StyledSelectNavbar spacing={1} p={1} {...rest}>
      {options.map((option) => (
        <ButtonSelect
          key={option.id.toString()}
          flex={'auto'}
          isActive={isActive(option)}
          onClick={() => onSelect?.(option)}
          py={1}
          px={[1, 1, 2, 4]}
          fontSize={3.5}
          children={option.label}
        />
      ))}
    </StyledSelectNavbar>
  )
}
