import { Box } from '@illuvium/illuvium-design'
import { withoutDomProps } from 'src/utils/helpers'
import styled from 'styled-components'

import { SmallSpinnerProps } from './index'

export const StyledSmallSpinner = styled(Box).withConfig(
  withoutDomProps('scale')
)<SmallSpinnerProps>`
  transform: scale(${({ scale }) => scale});
`
