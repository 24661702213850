import { atom } from 'recoil'

export interface GasPrices {
  fast: string
  standard: string
  slow: string
}

export const gasTrackerAtom = atom<GasPrices | null>({
  key: 'gasTrackerAtom',
  default: null,
})
