import { Box } from '@illuvium/illuvium-design'
import styled from 'styled-components/macro'

export const DefaultBox = styled(Box)`
  height: 1px;
  width: 100%;
`

export const DividerBox = styled(DefaultBox)`
  height: 1.1px;
  background-image: linear-gradient(
    90deg,
    rgba(171, 84, 244, 0),
    rgb(171, 84, 244, 0.5) 25%,
    rgba(255, 255, 255, 1) 50%,
    rgb(171, 84, 244, 0.5) 75%,
    rgba(171, 84, 244, 0)
  );
`

export const BasicDividerBox = styled(DefaultBox)`
  border: 1px solid rgba(255, 255, 255, 0.08);
`

export const HoloDividerBox = styled(DefaultBox)`
  height: 1px;
  width: 100%;

  background-image: linear-gradient(
    270deg,
    rgba(9, 228, 97, 0) 0%,
    #ceef00 17.19%,
    #51f980 34.38%,
    #2d51ed 50%,
    #0060f1 67.19%,
    #f100d9 83.33%,
    rgba(45, 73, 85, 0) 99.75%
  );
`

export const VerticalDividerBox = styled(DefaultBox)`
  background-image: linear-gradient(
    180deg,
    rgba(171, 84, 244, 0),
    rgb(171, 84, 244, 0.5) 25%,
    rgba(255, 255, 255, 1) 50%,
    rgb(171, 84, 244, 0.5) 75%,
    rgba(171, 84, 244, 0)
  );
  height: 50px;
  width: 1px;
`
