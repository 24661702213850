import { treeData } from 'src/merkle-proofs/raw-data'
import { UserDataProps } from 'src/recoil/user-data'

export const getUserData = async (
  account: string
): Promise<UserDataProps | undefined> => {
  return new Promise((resolve, reject) => {
    try {
      const userData = treeData
        .map((item, index: number) =>
          item.account === account ? { ...item, index } : undefined
        )
        .filter((item) => item !== undefined)[0]

      resolve(userData)
    } catch (error) {
      reject(error)
    }
  })
}
