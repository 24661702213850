import { Box, BoxProps } from '@illuvium/illuvium-design'
import React from 'react'
import { defaultTheme } from 'src/styles/theme'

const { layout } = defaultTheme

export const Container: React.FC<BoxProps> = (props) => {
  const { ...rest } = props

  return (
    <Box
      mx={'auto'}
      px={{ base: 8, md: 11, lg: 4 }}
      py={12}
      maxW={layout.xLarge}
      h={'full'}
      {...rest}
    />
  )
}
